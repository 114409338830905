import emoji from 'react-easy-emoji';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Highlight = styled(Link)`
  color: ${({ theme }) => theme.colors.highlight};
  fontweight: ${({ $iscurrentuser }) => ($iscurrentuser ? '600' : '500')};
`;

const WordTagging = ({ text, linkTo }) => {
  const currentUser = useSelector((state) => state.currentUser);

  let words = [];
  if (text) {
    words = text.split(' ');
  } else {
    return '';
  }

  return words.map((word, index) => {
    const wordWSpace = `${word} `;
    const isCurrentUser = word.substr(1) === currentUser.username;

    if (word.charAt(0) === '@') {
      return (
        <Highlight
          to={linkTo(word)}
          key={index}
          $iscurrentuser={isCurrentUser.toString()}
        >
          {wordWSpace}&nbsp;
        </Highlight>
      );
    }

    return <span key={index}>{emoji(wordWSpace)}</span>;
  });
};

export default WordTagging;
