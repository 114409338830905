import bardAPI from '@/bard-api';
import TextButton from '@/components/library/buttons/text-button';
import { ImportantText } from '@/components/library/typography/text';
import { fetchUserAfterUpdate } from '@/store/user';
import { uRequestHandler } from '@/utils';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Notification from './notification';
import Wrapper from './wrapper';

const NotificationPanel = ({
  notifications,
  showNotifications,
  clearNotifications,
  getNotifications,
}) => {
  const dispatch = useDispatch();

  const markAllAsRead = async () => {
    try {
      await bardAPI.post(`notifications/read/`);
      clearNotifications();
      dispatch(fetchUserAfterUpdate());
      showNotifications();
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const notificationList = () => {
    return notifications.map((notification) => {
      return (
        <Notification
          notification={notification}
          getNotifications={getNotifications}
          panel={true}
        />
      );
    });
  };

  return (
    <Wrapper>
      <div className="notification-panel-header">
        <div className="notification-panel-options">
          <ImportantText>Notifications</ImportantText>
          <TextButton onClick={() => markAllAsRead()}>
            Mark all as read
          </TextButton>
        </div>
      </div>
      <div className="notification-panel-body">
        {notificationList()}
        {notifications.length === 0 && (
          <div className="notification-panel-emptystate">
            You have no new notifications
          </div>
        )}
      </div>
      <div className="notification-panel-footer">
        <Link to="/notifications">View past notifications</Link>
      </div>
    </Wrapper>
  );
};

export default NotificationPanel;
