import { useNavigate } from 'react-router';
import AudioPlayer from '../audio-player';

const AudioPiece = ({ audio, collab }) => {
  const navigate = useNavigate();

  return (
    <div className="audio-container">
      <AudioPlayer
        id={`collab-audio-${collab.id}`}
        audio={audio}
        onTitleClicked={() => navigate(`/${collab.id}`)}
      />
    </div>
  );
};

export default AudioPiece;
