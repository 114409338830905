import bardAPI from '@/bard-api';
import { FlexRowGap } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import LoadingEmptyState from '@/components/library/loading-empty-state';
import { ItemHeader } from '@/components/library/typography/header';
import Navbar from '@/components/navbar/index';
import AudioPost from '@/components/pieces/audio-post';
import VisualPost from '@/components/pieces/visual-post';
import WritingPost from '@/components/pieces/writing-post';
import ProfileDetails from '@/components/profile-details';
import ScrollablePage from '@/components/scrollable-page';
import UserImgDefault from '@/components/user-img-default';
import { uRequestHandler } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { XBlock, XMasonry } from 'react-xmasonry';
import styled from 'styled-components';
import { ProfileRight, ProFlag, SocialPostsAllowedDot } from './components';
import Wrapper from './wrapper';

const PostingWrapper = styled.div`
  border: none;
  margin: 8px;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
  }

  .visual-container,
  .audio-container,
  .writing-container {
    padding: 18px;
    background-color: ${({ theme }) => theme.colors.card.background};
    border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
    border-radius: 6px;
  }
`;

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [isCurrentUserFollowing, setIsCurrentUserFollowing] = useState(false);
  const [followId, setFollowId] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);
  const [followCount, setFollowCount] = useState(0);
  const [visuals, setVisuals] = useState([]);
  const [audios, setAudios] = useState([]);
  const [writings, setWritings] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalVisuals, setTotalVisuals] = useState([]);
  const [totalAudios, setTotalAudios] = useState([]);
  const [totalWritings, setTotalWritings] = useState([]);
  const [searching, setSearching] = useState(false);
  const [offset, setOffset] = useState(0);

  const { username } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);

  const checkIfFollowing = useCallback(() => {
    if (currentUser.id) {
      for (let i = 0; i < currentUser.following.length; i += 1) {
        if (currentUser.following[i].followed === Number(profile.id)) {
          setIsCurrentUserFollowing(true);
          setFollowId(currentUser.following[i].id);
        }
      }
    }

    const getInitialData = async () => {
      setSearching(true);
      const response = await bardAPI.get(
        `visuals/?user=${profile.id}&offset=0&limit=10`,
      );
      setTotalVisuals(response.data.count);
      setVisuals(response.data.results);
      const response2 = await bardAPI.get(
        `audios/?user=${profile.id}&offset=0&limit=10`,
      );
      setTotalAudios(response2.data.count);
      setAudios(response2.data.results);
      const response3 = await bardAPI.get(
        `writings/?user=${profile.id}&offset=0&limit=10`,
      );
      setTotalWritings(response3.data.count);
      setWritings(response3.data.results);

      setAllData(
        [
          ...response.data.results,
          ...response2.data.results,
          ...response3.data.results,
        ].sort((a, b) => {
          return new Date(b.created_date) - new Date(a.created_date);
        }),
      );
      setSearching(false);
    };

    if (profile.id) {
      getInitialData();
    }
  }, [currentUser, profile]);

  const getUserProfile = useCallback(async () => {
    setAllData([]);
    setOffset(0);

    try {
      const user = await bardAPI.get(`users/?username=${username}`);
      if (user.data.length === 0) {
        navigate('/notfound');
      }
      const userData = user.data[0];

      setProfile(userData);
      setFollowerCount(userData.followers.length);
      setFollowCount(userData.following.length);
    } catch (error) {
      uRequestHandler(error);
    }
  }, [history, username]);

  const setPage = async (page) => {
    setSearching(true);
    setOffset(page);

    let newVisuals = [];
    let newAudios = [];
    let newWritings = [];
    if (visuals.length < totalVisuals) {
      const response = await bardAPI.get(
        `visuals/?user=${profile.id}&offset=${page * 10}&limit=10`,
      );
      setTotalVisuals(response.data.count);
      setVisuals([...visuals, ...response.data.results]);
      newVisuals = response.data.results;
    }
    if (audios.length < totalAudios) {
      const response = await bardAPI.get(
        `audios/?user=${profile.id}&offset=${page * 10}&limit=10`,
      );
      setTotalAudios(response.data.count);
      setAudios([...audios, ...response.data.results]);
      newAudios = response.data.results;
    }
    if (writings.length < totalWritings) {
      const response = await bardAPI.get(
        `writings/?user=${profile.id}&offset=${page * 10}&limit=10`,
      );
      setTotalWritings(response.data.count);
      setWritings([...writings, ...response.data.results]);
      newWritings = response.data.results;
    }
    const newSetData = [...newVisuals, ...newAudios, ...newWritings].sort(
      (a, b) => {
        return new Date(b.created_date) - new Date(a.created_date);
      },
    );
    setAllData((allData) => [...allData, ...newSetData]);
    setSearching(false);
  };

  useEffect(() => {
    setVisuals([]);
    setAudios([]);
    setWritings([]);
    getUserProfile();
  }, [username, getUserProfile]);

  useEffect(() => {
    checkIfFollowing();
  }, [checkIfFollowing]);

  const unFollow = async () => {
    try {
      await bardAPI.delete(`follows/${followId}`);
      setIsCurrentUserFollowing(false);
      setFollowId(0);
      setFollowerCount(followerCount - 1);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const follow = async () => {
    try {
      const response = await bardAPI.post(`follows/`, {
        followed_id: profile.id,
        follower_id: currentUser.id,
      });
      setIsCurrentUserFollowing(true);
      setFollowId(response.data.id);
      setFollowerCount(followerCount + 1);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const renderBadges = () => {
    return profile.badges.map((assignment) => (
      <div title={assignment.badge.popup} key={assignment.badge.id}>
        <img
          src={assignment.badge.file_path}
          alt="Badge"
          style={{
            width: 60,
            height: 60,
          }}
        />
      </div>
    ));
  };

  const renderContent = () => {
    return allData.map((post) => {
      const isVisual = post.file_path && post.file_path.includes('visual');
      const isAudio = post.file_path && post.file_path.includes('audio');
      const isWriting = post.writing;

      let key = `visual-${post.id}`;
      if (isAudio) {
        key = `audio-${post.id}`;
      }
      if (isWriting) {
        key = `writing=${post.id}`;
      }
      return (
        <XBlock key={key}>
          <PostingWrapper>
            {isVisual && (
              <VisualPost
                post={post}
                selectedList={[]}
                setSelected={() => {}}
                selectable={false}
                isMultiSelect={false}
              />
            )}
            {isWriting && (
              <WritingPost
                post={post}
                setSelected={() => {}}
                selectable={false}
              />
            )}
            {isAudio && (
              <AudioPost
                post={post}
                setSelected={() => {}}
                selectable={false}
              />
            )}
          </PostingWrapper>
        </XBlock>
      );
    });
  };

  const isViewingSelf = currentUser.id === profile.id;
  const isPageLoaded = profile.id;
  return (
    <PageContainer>
      <Navbar />
      <ScrollablePage
        pageId="profile-page"
        isLoading={searching}
        currentData={[]}
        totalData={9999999}
        setPage={(page) => setPage(page)}
        page={offset}
      >
        <ContentContainer>
          {isPageLoaded && (
            <>
              <Wrapper>
                <div className="profile-picture">
                  <UserImgDefault user={profile} size="lg" />
                  {profile.has_pro_features && <ProFlag>PRO</ProFlag>}
                  {profile.social_posts_allowed && currentUser.is_admin && (
                    <SocialPostsAllowedDot />
                  )}
                </div>
                <ProfileDetails user={profile} />

                <ProfileRight>
                  <FlexRowGap $gap="12px">
                    <ItemHeader>{profile.collab_count} Posts</ItemHeader>
                    {isViewingSelf && (
                      <ItemHeader $desktopOnly>
                        {followerCount} Followers
                      </ItemHeader>
                    )}
                    {isViewingSelf && (
                      <Link to="/following/">
                        <ItemHeader>{followCount} Following</ItemHeader>
                      </Link>
                    )}
                    {!isViewingSelf && currentUser.id && (
                      <div className="profile-follow">
                        {isCurrentUserFollowing ? (
                          <PrimaryButton className="btn" onClick={unFollow}>
                            Unfollow
                          </PrimaryButton>
                        ) : (
                          <PrimaryButton className="btn" onClick={follow}>
                            Follow
                          </PrimaryButton>
                        )}
                      </div>
                    )}
                  </FlexRowGap>

                  <Link to="/badges">
                    <FlexRowGap $gap="12px">{renderBadges()}</FlexRowGap>
                  </Link>
                </ProfileRight>
              </Wrapper>
              {/* <ProfileTabs>{renderTabs()}</ProfileTabs> */}
              <XMasonry maxColumns={3} targetBlockWidth={400}>
                {renderContent()}
              </XMasonry>
              <LoadingEmptyState
                noData="This user has no posts yet."
                isLoading={searching}
                data={allData}
              />
            </>
          )}
        </ContentContainer>
      </ScrollablePage>
    </PageContainer>
  );
};

export default Profile;
