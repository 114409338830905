import bardAPI from '@/bard-api';
import S3Visual from '@/components/library/s3-visual';
import PostLayout from '@/components/post-layout';
import { uRequestHandler, VISUAL_REPORT } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Visual = () => {
  const [visual, setVisual] = useState({});
  const [collabs, setCollabs] = useState([]);
  const [collections, setCollections] = useState([]);
  const { id: visualId } = useParams();

  const getInitialData = useCallback(async () => {
    try {
      const visual = await bardAPI.get(`visuals/${visualId}`);
      const collabs = await bardAPI.get(`collabs/?visual_id=${visualId}`);
      const collections = await bardAPI.get(`collections/?visual=${visualId}`);
      setVisual(visual.data);
      setCollabs(collabs.data);
      setCollections(collections.data);
    } catch (error) {
      uRequestHandler(error);
    }
  }, [visualId]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const savePost = async (genericData) => {
    try {
      const newVisual = await bardAPI.patch(
        `visuals/${visualId}/`,
        genericData,
      );

      setVisual(newVisual.data);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  return (
    <PostLayout
      post={visual}
      collabs={collabs}
      collections={collections}
      reportCategory={VISUAL_REPORT}
      linkRoot={'visual'}
      content={<S3Visual path={visual.file_path} enableEnlarge={true} />}
      savePost={savePost}
    />
  );
};

export default Visual;
