import { Link } from 'react-router-dom';
import { XBlock, XMasonry } from 'react-xmasonry';
import styled from 'styled-components';

import { ItemHeader } from '@/components/library/typography/header';
import { SubText, Text } from '@/components/library/typography/text';

const Collection = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 12px 18px;
  background-color: ${({ theme }) => theme.colors.card.background};
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  border-radius: 8px;
  width: 96%;
  margin: auto;
  margin-bottom: 20px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const CollectionsList = ({ collections }) => {
  return (
    <div style={{ width: '102%', marginLeft: '-1%' }}>
      <XMasonry maxColumns={3} targetBlockWidth={400} center={false}>
        {collections.map((collection, _) => (
          <XBlock key={collection.id}>
            <Link to={`/collection/${collection.id}`}>
              <Collection>
                <ItemHeader $margin="0 0 0.25rem">{collection.name}</ItemHeader>
                <SubText $margin="0 0 0.4rem">{collection.posts} posts</SubText>
                <Text>{collection.about}</Text>
              </Collection>
            </Link>
          </XBlock>
        ))}
      </XMasonry>
    </div>
  );
};

export default CollectionsList;
