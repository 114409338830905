import bardAPI from '@/bard-api';
import Comment from '@/components/comment';
import PrimaryButton from '@/components/library/buttons/primary-button';
import FormFooter from '@/components/library/form-footer';
import LoadingEmptyState from '@/components/library/loading-empty-state';
import { SectionHeader } from '@/components/library/typography/header';
import NotSignedIn from '@/components/modals/not-signed-in';
import SearchDropdown from '@/components/search-dropdown';
import { uRequestHandler } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { MessageCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { scroller } from 'react-scroll';
import { Form } from 'semantic-ui-react';

const Comments = ({ collab, post }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [comments, setComments] = useState([]);
  const [search, setSearch] = useState('');
  const [containerReady, setContainerReady] = useState(false);
  const [showMustBeSigned, setShowMustBeSigned] = useState(false);
  const location = useLocation();

  const setDefaultCommentTags = useCallback(
    async (viewable) => {
      let defaultComment = '';

      if (
        viewable.visual &&
        !defaultComment.includes(viewable.visual.user.username) &&
        viewable.visual.user.username !== currentUser.username
      ) {
        defaultComment += `@${viewable.visual.user.username} `;
      }
      if (
        viewable.audio &&
        !defaultComment.includes(viewable.audio.user.username) &&
        viewable.audio.user.username !== currentUser.username
      ) {
        defaultComment += `@${viewable.audio.user.username} `;
      }
      if (
        viewable.writing &&
        !defaultComment.includes(viewable.writing.user.username) &&
        viewable.writing.user.username !== currentUser.username
      ) {
        defaultComment += `@${viewable.writing.user.username} `;
      }
      // Hacky way of checking if the viewable is a post
      if (viewable.user) {
        defaultComment += `@${viewable.user.username} `;
      }

      setSearch(defaultComment);
    },
    [currentUser],
  );

  const getComments = useCallback(async () => {
    let filter = '';
    let viewable = {};
    if (collab && collab.id) {
      filter = `collab=${collab.id}`;
      viewable = collab;
    } else if (post && post.id) {
      filter = `post=${post.id}`;
      viewable = post;
    }

    try {
      if ((collab && collab.id) || (post && post.id)) {
        const comments = await bardAPI.get(`comments/?${filter}`);
        setDefaultCommentTags(viewable);
        setComments(comments.data);
        setContainerReady(true);
      }
    } catch (error) {
      uRequestHandler(error);
    }
  }, [collab, setDefaultCommentTags, post]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  useEffect(() => {
    setTimeout(() => {
      if (location.query && location.query.commentId) {
        scroller.scrollTo(location.query.commentId, {
          duration: 800,
          delay: 100,
          smooth: true,
        });
      }
    }, 100);
  }, [comments, location]);

  const updateCommentsList = (newComment) => {
    const updatedComments = [...comments];

    for (let i = 0; i < updatedComments.length; i += 1) {
      const possibleParentComment = updatedComments[i];
      if (possibleParentComment.id === newComment.parent_comment_id) {
        updatedComments[i].child_comments.push(newComment);
      }
    }

    setComments(updatedComments);
  };

  const handleSubmit = () => {
    const newComment = {
      comment: search,
      user_id: currentUser.id,
      collab: collab ? collab.id : null,
      post: post ? post.id : null,
    };

    if (currentUser.id) {
      bardAPI
        .post(`comments/`, newComment)
        .then((result) => {
          const updatedComments = [result.data].concat(comments);
          setComments(updatedComments);
          setSearch('');
        })
        .catch((error) => {
          uRequestHandler(error);
        });
    } else {
      setShowMustBeSigned(true);
    }
  };

  const removeData = () => {
    getComments();
  };

  const renderComments = () => {
    return comments.map((comment) => {
      return (
        <div key={comment.id}>
          <Comment
            comment={comment}
            parentCommentId={comment.id}
            collab={collab}
            post={post}
            depth={1}
            updateCommentsList={updateCommentsList}
            removeData={removeData}
          />
        </div>
      );
    });
  };

  const isPost = post && post.id;
  const isCollab = !isPost;
  return (
    <div>
      {isCollab && <SectionHeader>Comments</SectionHeader>}
      <Form>
        <Form.Field>
          <SearchDropdown
            handleChange={setSearch}
            defaultComment={search}
            placeholder={'Leave a comment...'}
            large={isCollab}
          />
        </Form.Field>
        <FormFooter>
          <PrimaryButton className="btn" onClick={handleSubmit}>
            <MessageCircle />
            Comment
          </PrimaryButton>
        </FormFooter>
      </Form>
      <div className="comments-list">
        {containerReady && renderComments()}
        <LoadingEmptyState
          noData="This post does not have any comments"
          isLoading={!containerReady}
          data={comments}
        />
      </div>
      <NotSignedIn
        showModal={showMustBeSigned}
        setModalVisibility={setShowMustBeSigned}
      />
    </div>
  );
};

export default Comments;
