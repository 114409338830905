import { PageContainer } from '@/components/library/containers';
import { useCallback, useEffect } from 'react';

const ScrollablePage = ({
  children,
  pageId,
  isLoading,
  currentData,
  totalData,
  setPage,
  page,
  modal = false,
}) => {
  const trackScrolling = useCallback(() => {
    const wrappedElement = document.getElementById(pageId);
    if (
      wrappedElement.getBoundingClientRect().bottom <=
        window.innerHeight + 700 &&
      !isLoading &&
      currentData.length < totalData
    ) {
      setPage(page + 1);
    }
  }, [page, isLoading, currentData, totalData, pageId, setPage]);

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return () => document.removeEventListener('scroll', trackScrolling);
  }, [trackScrolling]);

  return (
    <PageContainer id={pageId} $modal={modal}>
      {children}
    </PageContainer>
  );
};

export default ScrollablePage;
