import bardAPI from '@/bard-api';
import { FlexRowGap } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import { CustomDropdown } from '@/components/library/dropdown';
import Modal from '@/components/library/modal-override';
import { ItemHeader } from '@/components/library/typography/header';
import { uRequestHandler } from '@/utils';
import { useState } from 'react';
import { X } from 'react-feather';
import { Form, TextArea } from 'semantic-ui-react';

const ReportModal = ({
  reportObject,
  onReport,
  category,
  showModal,
  setModalVisibility,
}) => {
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState(null);
  const [complete, setComplete] = useState(false);

  const REPORT_REASONS = [
    {
      key: 6,
      value: 6,
      text: 'Stolen artwork',
    },
    {
      key: 1,
      value: 1,
      text: 'I do not like it',
    },
    {
      key: 2,
      value: 2,
      text: 'It is spam',
    },
    {
      key: 3,
      value: 3,
      text: 'Nudity or pornography',
    },
    {
      key: 4,
      value: 4,
      text: 'Hate speech or symbols',
    },
    {
      key: 5,
      value: 5,
      text: 'Other',
    },
  ];

  const submitReport = async (event) => {
    event.preventDefault();
    try {
      await bardAPI.post(`reports/`, {
        reason,
        description,
        related_obj: reportObject.id,
        category,
      });

      setComplete(true);
      onReport();
    } catch (error) {
      uRequestHandler(error);
    }
  };

  return (
    <Modal size="tiny" open={showModal}>
      <Modal.Header>
        {complete ? (
          <ItemHeader>Thank you!</ItemHeader>
        ) : (
          <ItemHeader>Submit Report</ItemHeader>
        )}
        <X onClick={() => setModalVisibility(false)} className="modal-close" />
      </Modal.Header>
      <Modal.Content>
        {complete ? (
          <p className="basic-text">Thanks for your report.</p>
        ) : (
          <Form>
            <Form.Field>
              <label>Reason</label>
              <CustomDropdown
                placeholder="Select Reason"
                fluid
                value={reason}
                selection
                name="reason"
                onChange={(e, data) => setReason(data.value)}
                options={REPORT_REASONS}
              />
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              <TextArea
                placeholder="Other information"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Field>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <FlexRowGap $gap="12px">
          <SecondaryButton
            className="btn"
            onClick={() => setModalVisibility(false)}
          >
            Close
          </SecondaryButton>
          {!complete && (
            <PrimaryButton className="btn" onClick={submitReport}>
              Report
            </PrimaryButton>
          )}
        </FlexRowGap>
      </Modal.Actions>
    </Modal>
  );
};

export default ReportModal;
