import themes from '@/themes/schema.js';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

const ThemeManager = ({ children }) => {
  const currentUser = useSelector((state) => state.currentUser);

  const getTheme = () => {
    let theme = currentUser.theme || 'brand';
    if (theme in themes) {
      return themes[theme];
    }

    return themes['brand'];
  };

  return <ThemeProvider theme={getTheme}>{children}</ThemeProvider>;
};

export default ThemeManager;
