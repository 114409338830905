import styled from 'styled-components';

export const SmallHorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border};
  margin: 12px 0;
`;

export const VerticalDivider = styled.div`
  height: 36px;
  margin: 0 8px;
  width: 1px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.border};
`;

// this works?
export const SelectedDot = styled.div`
  color: ${({ theme }) => theme.colors.invertText};
  background-color: ${({ theme }) => theme.colors.highlight};
  border-radius: 50px;
  font-weight: 600;
  padding: 3px;
  position: absolute;
  height: 36px;
  width: 36px;
  line-height: 30px;
  text-align: center;
  top: 25px;
  left: 24px;
`;

export const FlexRowSpace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ $margin }) => ($margin ? $margin : 'initial')};
  gap: ${({ $gap }) => ($gap ? $gap : '4px')};
`;

export const FlexRowGap = styled.div`
  display: flex;
  gap: ${({ $gap }) => $gap || '0px'};
  margin: ${({ $margin }) => ($margin ? $margin : 'initial')};
  align-items: center;
  width: ${({ $width }) => ($width ? $width : 'auto')};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ $align }) => ($align ? $align : 'auto')};
  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : 0)};
  gap: ${({ $gap }) => ($gap ? $gap : 0)};
`;

export const FlexColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : 0)};
  gap: ${({ $gap }) => ($gap ? $gap : 0)};
`;

export const PanelMask = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  z-index: 10;
`;

export const Pill = styled.span`
  background-color: ${({ theme }) => theme.colors.highlight};
  color: ${({ theme }) => theme.colors.invertText};
  font-weight: 600;
  padding: 1px 5px;
  border-radius: 4px;
  margin-right: 4px;
`;
