import { FootNote, SemiImportant } from '@/components/library/typography/text';
import UserImgDefault from '@/components/user-img-default';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MoreOptions from './modals/more-options';

const UserHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $marginbottom }) => $marginbottom};
  .author-block {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const UserHeader = ({
  user,
  reportObject,
  reportCategory,
  createdDate,
  removeData,
  modalHeader,
}) => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <UserHeaderWrapper $marginbottom={modalHeader ? '0px' : '8px'}>
      <Link to={`/profile/${user.username}`}>
        <div className="author-block">
          <UserImgDefault user={user} size="sm" />
          <SemiImportant>{user.username}</SemiImportant>
          {createdDate && (
            <>
              <span>&middot;</span>
              <FootNote>{moment.utc(createdDate).local().fromNow()}</FootNote>
            </>
          )}
        </div>
      </Link>
      {currentUser.id && (
        <MoreOptions
          reportObject={reportObject}
          category={reportCategory}
          removeData={removeData}
        />
      )}
    </UserHeaderWrapper>
  );
};

export default UserHeader;
