import { Link } from 'react-router-dom';
import { Writing } from './writing-piece';

const WritingPost = ({ selectable, setSelected, post }) => {
  return (
    <div className="writing-container">
      {selectable ? (
        <div className="selectable-content" onClick={setSelected}>
          <Writing
            className="basic-text writing"
            dangerouslySetInnerHTML={{ __html: post.writing }}
          />
        </div>
      ) : (
        <Link to={`/writing/${post.id}`}>
          <Writing
            className="basic-text writing"
            dangerouslySetInnerHTML={{ __html: post.writing }}
          />
        </Link>
      )}
    </div>
  );
};

export default WritingPost;
