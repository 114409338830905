import bardAPI from '@/bard-api';
import { DropdownItem, DropdownMenu } from '@/components/library/dropdown';
import ConfirmModal from '@/components/modals/confirm-modal';
import { CollabContext } from '@/containers/collaboration/index';
import { resetFrontPage } from '@/store/landing';
import {
  AUDIO_REPORT,
  COMMENT_REPORT,
  POST_REPORT,
  uRequestHandler,
  USER_REPORT,
  VISUAL_REPORT,
  WRITING_REPORT,
} from '@/utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { MoreVertical } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import ReportModal from './report-modal';

const MoreOptions = ({ reportObject, category, removeData }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [reported, setReported] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const collab = useContext(CollabContext);
  const dispatch = useDispatch();

  const checkIfReported = useCallback(async () => {
    if (reportObject.reports) {
      for (let i = 0; i < reportObject.reports.length; i += 1) {
        const report = reportObject.reports[i];
        if (report.user.id === currentUser.id) {
          setReported(true);
        }
      }
    }
  }, [reportObject, currentUser]);

  useEffect(() => {
    checkIfReported();
  }, [checkIfReported]);

  const deleteItem = async () => {
    const endpoints = {
      [VISUAL_REPORT]: 'visuals',
      [AUDIO_REPORT]: 'audios',
      [WRITING_REPORT]: 'writings',
      [POST_REPORT]: 'posts',
      [COMMENT_REPORT]: 'comments',
      [USER_REPORT]: 'users',
    };

    try {
      await bardAPI.delete(`${endpoints[category]}/${reportObject.id}/`);
      removeData(reportObject.id);
      dispatch(resetFrontPage());
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const removeItem = async () => {
    const endpoints = {
      [VISUAL_REPORT]: {
        visual: null,
      },
      [AUDIO_REPORT]: {
        audio: null,
      },
      [WRITING_REPORT]: {
        writing: null,
      },
    };

    const patchObject = endpoints[category];

    try {
      await bardAPI.patch(`collabs/${collab.id}/`, patchObject);
      dispatch(resetFrontPage());
      removeData(reportObject.id);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const ownedByUser = reportObject.user.id === currentUser.id;
  return (
    <>
      <Dropdown
        inline
        icon={null}
        direction="left"
        trigger={<MoreVertical className="more-options-button" />}
        className="item"
      >
        <DropdownMenu>
          {reported ? (
            <DropdownItem color="#F3546F">Reported</DropdownItem>
          ) : (
            <DropdownItem onClick={() => setShowReportModal(true)}>
              Report
            </DropdownItem>
          )}
          {ownedByUser && collab && (
            <DropdownItem onClick={() => setShowRemoveModal(true)}>
              Remove
            </DropdownItem>
          )}
          {ownedByUser && !collab && (
            <DropdownItem onClick={() => setShowDeleteModal(true)}>
              Delete
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      <ReportModal
        showModal={showReportModal}
        setModalVisibility={setShowReportModal}
        category={category}
        reportObject={reportObject}
        onReport={() => setReported()}
      />
      <ConfirmModal
        showModal={showRemoveModal}
        setModalVisibility={setShowRemoveModal}
        action={removeItem}
        header="Remove from collaboration"
        confirmation="Are you sure you want to remove this artwork from this collaboration? This will not delete the post from your account."
        actionText="Remove post"
      />
      <ConfirmModal
        showModal={showDeleteModal}
        setModalVisibility={setShowDeleteModal}
        action={deleteItem}
        header="Delete post"
        confirmation="Are you sure you want to delete this post?"
        actionText="Delete post"
      />
    </>
  );
};

export default MoreOptions;
