import { SectionHeader } from '@/components/library/typography/header';
import { useEffect, useState } from 'react';
import ArtistSearchWrapper from './artist-search-wrapper';

const CONTENT_DEFAULT = {
  label: 'All Content',
  filter: '',
};

const ArtistSearch = ({ search, searching }) => {
  const [text, setText] = useState('');
  const [nextSearchString, setNextSearchString] = useState('');
  const [contentType, setContentType] = useState(CONTENT_DEFAULT);

  const changeText = (newText) => {
    setText(newText);
    searchArtists(newText, contentType);
  };

  const changeContent = (newContent) => {
    setContentType(newContent);
    searchArtists(text, newContent);
  };

  const searchArtists = (searchText, searchType) => {
    let searchString = `search=${searchText}&`;

    if (searchType) {
      searchString += `${searchType.filter}=true&`;
    }

    if (searching) {
      setNextSearchString(searchString);
    } else {
      search(searchString);
    }
  };

  useEffect(() => {
    if (!searching && nextSearchString !== '') {
      search(nextSearchString);
      setNextSearchString('');
    }
  }, [searching, nextSearchString, search]);

  const contentTypeFilters = [
    CONTENT_DEFAULT,
    {
      label: 'Visual',
      filter: 'visual',
    },
    {
      label: 'Music',
      filter: 'audio',
    },
    {
      label: 'Writing',
      filter: 'writing',
    },
    {
      label: 'Voice Line',
      filter: 'voice',
    },
  ];

  return (
    <ArtistSearchWrapper>
      <div className="search-wrapper">
        <SectionHeader>Search</SectionHeader>
        <input
          className="search-bar"
          name="search"
          placeholder="Search for usernames"
          value={text}
          onChange={(e) => changeText(e.target.value)}
        />
      </div>
    </ArtistSearchWrapper>
  );
};

export default ArtistSearch;
