import bardAPI from '@/bard-api';
import PostLayout from '@/components/post-layout';
import { uRequestHandler, WRITING_REPORT } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';

const Writing = () => {
  const [writing, setWriting] = useState({});
  const [collabs, setCollabs] = useState([]);
  const [collections, setCollections] = useState([]);
  const [story, setStory] = useState('');
  const { id: writingId } = useParams();

  const getInitialData = useCallback(async () => {
    try {
      const writing = await bardAPI.get(`writings/${writingId}`);
      const collabs = await bardAPI.get(`collabs/?writing_id=${writingId}`);
      const collections = await bardAPI.get(
        `collections/?writing=${writingId}`,
      );
      setCollabs(collabs.data);
      setWriting(writing.data);
      setStory(writing.data.writing);
      setCollections(collections.data);
    } catch (error) {
      uRequestHandler(error);
    }
  }, [writingId]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const savePost = async (genericData) => {
    try {
      const newWriting = await bardAPI.patch(`writings/${writing.id}/`, {
        ...{ writing: story },
        ...genericData,
      });

      setWriting(newWriting.data);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  return (
    <PostLayout
      post={writing}
      collabs={collabs}
      collections={collections}
      reportCategory={WRITING_REPORT}
      linkRoot={'writing'}
      content={<p dangerouslySetInnerHTML={{ __html: story }} />}
      savePost={savePost}
    />
  );
};

export default Writing;
