import styled from 'styled-components';

const Dot = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.highlight};
  color: ${({ theme }) => theme.colors.bodyText};
  height: 10px;
  width: 10px;
  border-radius: 20px;
  margin: ${({ $margin }) => ($margin ? $margin : '0px')};
`;

export default Dot;
