import bardAPI from '@/bard-api';

import ActiveCollection from '@/components/active-collection';
import AddAudio from '@/components/add-audio';
import AddVisual from '@/components/add-visual';
import AddWriting from '@/components/add-writing';
import { FlexColumn } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import EmptyBlock from '@/components/library/empty-block';
import PageModalHeader from '@/components/library/page-modal-header';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { Edit2, Headphones, Image } from 'react-feather';
import { useParams } from 'react-router-dom';

const NewCollab = () => {
  const [editing, setEditing] = useState(null);
  const [collection, setCollection] = useState(null);
  const { id: collectionId } = useParams();

  const EDITING_VISUAL = 0;
  const EDITING_AUDIO = 1;
  const EDITING_WRITING = 2;

  const getCollection = async () => {
    if (!collectionId) return;

    try {
      const response = await bardAPI.get(`collections/${collectionId}/`);
      setCollection(response.data);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  useEffect(() => {
    getCollection();
  }, []);

  useEffect(() => {
    getCollection();
  }, [collectionId]);

  return (
    <PageContainer $modal={true}>
      <ContentContainer>
        <PageModalHeader
          location={'/'}
          title={
            editing === null
              ? 'Start by selecting what you want to upload'
              : 'Upload your work'
          }
        />

        {collection && <ActiveCollection collection={collection} />}

        <FlexColumn $gap="24px">
          {editing === EDITING_VISUAL && (
            <AddVisual forceUploadNew resetPage={() => setEditing(null)} />
          )}
          {editing === EDITING_AUDIO && (
            <AddAudio forceUploadNew resetPage={() => setEditing(null)} />
          )}
          {editing === EDITING_WRITING && (
            <AddWriting forceUploadNew resetPage={() => setEditing(null)} />
          )}

          {editing === null && (
            <>
              <EmptyBlock>
                <PrimaryButton
                  className="btn"
                  onClick={() => setEditing(EDITING_VISUAL)}
                >
                  <Image />
                  Add a Visual
                </PrimaryButton>
              </EmptyBlock>
              <EmptyBlock>
                <PrimaryButton
                  className="btn"
                  onClick={() => setEditing(EDITING_AUDIO)}
                >
                  <Headphones />
                  Add a Piece of Music
                </PrimaryButton>
              </EmptyBlock>
              <EmptyBlock>
                <PrimaryButton
                  className="btn"
                  onClick={() => setEditing(EDITING_WRITING)}
                >
                  <Edit2 />
                  Add a Story or Poem
                </PrimaryButton>
              </EmptyBlock>
            </>
          )}
        </FlexColumn>
      </ContentContainer>
    </PageContainer>
  );
};

export default NewCollab;
