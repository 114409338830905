import { useState } from 'react';
import { Lightbox } from 'react-modal-image';
import styled from 'styled-components';

const Wrapper = styled.div`
  line-height: 0;

  button {
    width: ${({ $wideImage }) => ($wideImage ? '110%' : '100%')};
    margin-left: ${({ $wideImage }) => ($wideImage ? '-5%' : 'auto')};
  }
  img {
    max-width: 100%;
    max-height: 1200px;
    margin: auto;
  }

  @media (max-width: 700px) {
    button {
      width: 100%;
    }
    img {
      width: 100%;
      margin-left: auto;
    }
  }
`;

const S3Visual = ({ path, enableWide, enableEnlarge }) => {
  const [wideImage, setWideImage] = useState(false);
  const [enlarge, setEnlarge] = useState(false);

  const onImgLoad = ({ target: img }) => {
    if (img.offsetWidth > img.offsetHeight) {
      setWideImage(true);
    }
  };

  const fullScreen = () => {
    setEnlarge(true);
  };

  return (
    <Wrapper $wideImage={wideImage && enableWide} className="s3-visual">
      {enlarge && enableEnlarge ? (
        <Lightbox
          medium={`https://${import.meta.env.VITE_APP_S3}/${path}`}
          alt="Visual"
          onClose={() => setEnlarge(false)}
          hideDownload={true}
          hideZoom={true}
        />
      ) : (
        <button onClick={fullScreen}>
          <img
            src={`https://${import.meta.env.VITE_APP_S3}/${path}`}
            alt="Unsuccessfully loaded."
            onLoad={onImgLoad}
          />
        </button>
      )}
    </Wrapper>
  );
};

export default S3Visual;
