import bardAPI from '@/bard-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const MAKER_FOLLOWING = 'following=true&';
export const MAKER_EXPLORE = 'hot=true&';
export const MAKER_LATEST = 'new=true&';
export const MAKERS = {
  [MAKER_FOLLOWING]: 'Following',
  [MAKER_EXPLORE]: 'Explore',
  [MAKER_LATEST]: 'Latest',
};

export const CONTENT_ALL = '';
const CONTENT_COLLABS = 'complete=true&';
const CONTENT_VISUAL = 'visual=true&';
const CONTENT_AUDIO = 'audio=true&';
const CONTENT_WRITING = 'writing=true&';
export const CONTENT = {
  [CONTENT_ALL]: 'All',
  [CONTENT_COLLABS]: 'Complete',
  [CONTENT_VISUAL]: 'Visual',
  [CONTENT_AUDIO]: 'Audio',
  [CONTENT_WRITING]: 'Writing',
};

const initialState = {
  offset: 0,
  total: 50,
  collaborations: [],
  reverseLikes: [],
  reverseSaves: [],
  tags: [],
  maker: MAKER_EXPLORE,
  contentType: CONTENT_ALL,
  isLoading: false,
};

export const getCollaborationsPage = createAsyncThunk(
  'collaborations/getCollaborationsPage',
  async (_, thunkAPI) => {
    const { landingPage } = thunkAPI.getState();
    const { offset, maker, tags, contentType, collaborations } = landingPage;

    let filter = '';
    for (let i = 0; i < tags.length; i += 1) {
      filter += `tag=${tags[i]}&`;
    }

    filter = filter + maker + contentType;

    const response = await bardAPI.get(
      `collabs/?${filter}limit=${12}&offset=${offset * 12}`,
    );
    return {
      results: [...collaborations, ...response.data.results],
      count: response.data.count,
    };
  },
);

const collaborationsSlice = createSlice({
  name: 'collaborations',
  initialState,
  reducers: {
    resetLikesAndSaves(state) {
      state.reverseLikes = [];
      state.reverseSaves = [];
    },
    likeCollab(state, action) {
      const newCollabId = action.payload;
      bardAPI.post(`like/`, {
        collab: newCollabId,
      });

      if (state.reverseLikes.includes(newCollabId)) {
        state.reverseLikes = state.reverseLikes.filter(
          (collabId) => collabId !== newCollabId,
        );
      } else {
        state.reverseLikes.push(newCollabId);
      }
    },
    saveCollab(state, action) {
      const newCollabId = action.payload;
      bardAPI.post(`save/`, {
        collab: newCollabId,
      });

      if (state.reverseSaves.includes(newCollabId)) {
        state.reverseSaves = state.reverseSaves.filter(
          (collabId) => collabId !== newCollabId,
        );
      } else {
        state.reverseSaves.push(newCollabId);
      }
    },
    resetFrontPage(state, action) {
      state.reverseLikes = [];
      state.reverseSaves = [];
      state.collaborations = [];
      state.offset = 0;
      state.total = 0;
    },
    setTags(state, action) {
      state.reverseLikes = [];
      state.reverseSaves = [];
      state.collaborations = [];
      state.offset = 0;
      state.total = 0;
      state.tags = action.payload;
    },
    setMaker(state, action) {
      if (state.maker !== action.payload) {
        state.reverseLikes = [];
        state.reverseSaves = [];
        state.collaborations = [];
        state.offset = 0;
        state.total = 0;
        state.maker = action.payload;
      }
    },
    setContentType(state, action) {
      if (state.contentType !== action.payload) {
        state.reverseLikes = [];
        state.reverseSaves = [];
        state.collaborations = [];
        state.offset = 0;
        state.total = 0;
        state.contentType = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCollaborationsPage.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCollaborationsPage.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getCollaborationsPage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offset = state.offset + 1;
      state.collaborations = action.payload.results;
      state.total = action.payload.count;
    });
  },
});

export const {
  setTags,
  resetFrontPage,
  setContentType,
  setMaker,
  setOrder,
  resetLikesAndSaves,
  likeCollab,
  saveCollab,
} = collaborationsSlice.actions;
export default collaborationsSlice.reducer;
