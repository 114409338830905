import TextButton from '@/components/library/buttons/text-button';
import { setTags } from '@/store/landing';
import { useState } from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

const Tag = styled.div`
  color: ${({ theme }) => theme.colors.highlight};
`;

const EditableTag = styled.div`
  background: ${({ theme }) => theme.colors.highlight};
  border: ${({ theme }) => `1px solid ${theme.colors.highlight}`};
  color: ${({ theme }) => theme.colors.invertText};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  padding: 0 6px;
  border-radius: 0.3rem;

  svg {
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.colors.invertText};
    &:hover {
      cursor: pointer;
    }
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const Tags = ({ updateTags, tags = [] }) => {
  const landingPage = useSelector((state) => state.landingPage);
  const dispatch = useDispatch();
  const { tags: filterTags } = landingPage;
  const [tag, setTag] = useState('');
  const isEditMode = updateTags;
  const navigate = useNavigate();

  const handleTags = (value) => {
    const lastLetter = value.substring(value.length - 1, value.length);
    // Remove whitespace and commas
    value = value.replace(/,/g, '');
    value = value.replace(/\s/g, '');

    const tagDone =
      value.length > 0 && (lastLetter === ' ' || lastLetter === ',');
    if (tagDone) {
      if (tags.includes(value)) {
        setTag('');
        return;
      }
      updateTags([...tags, value]);
      setTag('');
    } else {
      setTag(value);
    }
  };

  const searchTag = (tagClicked) => {
    if (!filterTags.includes(tagClicked)) {
      dispatch(setTags([...filterTags, tagClicked]));
    }
    navigate('/');
  };

  const removeTag = (tag) => {
    const index = tags.indexOf(tag);
    if (index > -1) {
      tags.splice(index, 1);
      updateTags([...tags]);
    }
  };

  const renderTags = () => {
    if (isEditMode) {
      return tags.map((tag) => {
        return (
          <EditableTag key={tag}>
            #{tag}
            <X
              role="link"
              tabIndex={0}
              onClick={() => removeTag(tag)}
              onKeyPress={() => removeTag(tag)}
            />
          </EditableTag>
        );
      });
    } else {
      return tags.map((tag) => {
        return (
          <TextButton key={tag.id} onClick={() => searchTag(tag)}>
            <Tag>#{tag}</Tag>
          </TextButton>
        );
      });
    }
  };

  return (
    <Wrapper>
      {isEditMode ? (
        <Form.Field style={{ width: '100%' }}>
          <label>Tags</label>
          <div>
            <input
              style={{ marginbottom: 12 }}
              placeholder="Press space to add a tag"
              name="tag"
              value={tag}
              onChange={(e) => handleTags(e.target.value)}
            />
            <TagWrapper>{renderTags()}</TagWrapper>
          </div>
        </Form.Field>
      ) : (
        <TagWrapper>{renderTags()}</TagWrapper>
      )}
    </Wrapper>
  );
};

export default Tags;
