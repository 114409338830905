import { ItemHeader } from '@/components/library/typography/header';
import { SubText } from '@/components/library/typography/text';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HoverOptionsWrapper = styled.div`
  width: 100vw;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.body};
  .explore-menu {
    max-width: 1200px;
    position: relative;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.body};
    margin: auto;
    padding: 12px 0;
    border-top: ${({ theme }) => `1px solid ${theme.colors.border}`};
    display: flex;
    gap: 12px;
    .explore-menu-option {
      width: 240px;
      padding: 12px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const HoverOptions = ({ showExplorer, hideExplorer }) => (
  <HoverOptionsWrapper onMouseEnter={showExplorer} onMouseLeave={hideExplorer}>
    <div className="explore-menu">
      <Link to="/" className="explore-menu-option">
        <ItemHeader $margin="0 0 8px 0">Collaborations</ItemHeader>
        <SubText>Browse collaborations between artists</SubText>
      </Link>
      <Link to="/collections" className="explore-menu-option">
        <ItemHeader $margin="0 0 8px 0">Collections</ItemHeader>
        <SubText>Browse worlds built by the community</SubText>
      </Link>
      <Link to="/artists" className="explore-menu-option">
        <ItemHeader $margin="0 0 8px 0">Artists</ItemHeader>
        <SubText>Find and explore artists and their services</SubText>
      </Link>
    </div>
  </HoverOptionsWrapper>
);

export default HoverOptions;
