import styled from 'styled-components';

const TagPanel = styled.div`
  z-index: 100;
  width: 480px;
  display: block;
  position: absolute;
  right: 0px;
  top: 40px;
  background-color: ${({ theme }) => theme.colors.card.background};
  border-radius: 8px;
  padding: 20px;
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  .filter-panel-content {
    padding: 6px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .filter-search-bar {
      display: flex;
      gap: 12px;
      align-items: center;
      flex-wrap: wrap;
      .filter-collabs-input {
        height: 30px;
        flex-grow: 1;
        min-width: 80px;
      }
      svg {
        flex-basis: 30px;
      }
    }
  }

  @media only screen and (max-width: 540px) {
    width: 96vw;
    right: -12px;
  }
`;

export default TagPanel;
