import styled from 'styled-components';

const NavbarRight = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  svg,
  img {
    vertical-align: middle;
  }
`;

export default NavbarRight;
