import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

export const DropdownMenu = styled(Dropdown.Menu)`
  background: ${({ theme }) => theme.colors.card.background} !important;
  color: ${({ theme }) => theme.colors.bodyText};
`;

export const DropdownItem = styled(Dropdown.Item)`
  background: ${({ theme }) => theme.colors.card.background};
  color: ${({ theme, color }) =>
    color ? color : theme.colors.bodyText} !important;
  text-align: center;
  padding: 4px 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const CustomDropdown = styled(Dropdown)`
  background: ${({ theme }) => theme.colors.input.background} !important;
  color: ${({ theme }) => theme.colors.bodyText} !important;

  &.selection {
    border-color: ${({ theme }) => theme.colors.input.border} !important;
    color: ${({ theme }) => theme.colors.bodyText} !important;
  }
  .menu {
    background: ${({ theme }) => theme.colors.input.background} !important;
    color: ${({ theme }) => theme.colors.bodyText} !important;
    border-color: ${({ theme }) => theme.colors.input.border} !important;
  }
  .menu > .item {
    background: ${({ theme }) => theme.colors.input.background} !important;
    color: ${({ theme }) => theme.colors.bodyText} !important;
    border-top: ${({ theme }) =>
      `1px solid ${theme.colors.input.border}`} !important;
  }
`;
