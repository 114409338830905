import bardAPI from '@/bard-api';
import Footer from '@/components/footer';
import PrimaryButton from '@/components/library/buttons/primary-button';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import PageIntro from '@/components/library/page-intro';
import { ItemHeader } from '@/components/library/typography/header';
import { SubText } from '@/components/library/typography/text';
import Navbar from '@/components/navbar/index';
import UserImg from '@/components/user-img';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';

const Following = () => {
  const [following, setFollowing] = useState([]);
  const [unFollowed, setUnFollowed] = useState([]);

  useEffect(() => {
    getFollowing();
  }, []);

  const getFollowing = async () => {
    try {
      const response = await bardAPI.get(`following/`);
      const following = response.data;
      following.sort((a, b) => a.followed.username < b.followed.username);
      setFollowing(response.data);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const followUser = (follow) => {
    bardAPI
      .post(`follows/`, {
        followed_id: follow.followed.id,
        follower_id: follow.follower.id,
      })
      .then((response) => {
        setUnFollowed(unFollowed.filter((followId) => followId !== follow.id));
        let updatedFollowing = [...following];
        updatedFollowing = updatedFollowing.filter(
          (item) => item.id !== follow.id,
        );
        updatedFollowing.push(response.data);
        updatedFollowing.sort(
          (a, b) => a.followed.username < b.followed.username,
        );
        setFollowing(updatedFollowing);
      })
      .catch((error) => {
        uRequestHandler(error);
      });
  };

  const unFollowUser = (followId) => {
    bardAPI
      .delete(`follows/${followId}/`)
      .then(() => {
        const updatedUnFollowed = [...unFollowed];
        updatedUnFollowed.push(followId);
        setUnFollowed(updatedUnFollowed);
      })
      .catch((error) => {
        uRequestHandler(error);
      });
  };

  return (
    <PageContainer>
      <Navbar />
      <ContentContainer>
        <PageIntro
          title="Following"
          intro="
            Curate your timeline here. We recommend following fewer people if
            possible so that you can really get to know them!
          "
        />

        <div
          style={{ maxWidth: '500px', margin: 'auto', marginBottom: '120px' }}
        >
          {following.map((follow) => (
            <div
              key={follow.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '24px',
              }}
            >
              <div
                className="following-users"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <UserImg user={follow.followed} />
                <div style={{ marginLeft: '8px', marginRight: '16px' }}>
                  <ItemHeader>{follow.followed.username}</ItemHeader>
                  <SubText>{follow.followed.bio}</SubText>
                </div>
              </div>
              {unFollowed.indexOf(follow.id) > -1 ? (
                <PrimaryButton
                  className="btn"
                  onClick={() => followUser(follow)}
                >
                  Follow
                </PrimaryButton>
              ) : (
                <SecondaryButton
                  className="btn"
                  onClick={() => unFollowUser(follow.id)}
                >
                  Unfollow
                </SecondaryButton>
              )}
            </div>
          ))}
        </div>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};

export default Following;
