import * as brand from '@/themes/brand.json';
import * as dark from '@/themes/dark.json';
import * as gold from '@/themes/gold.json';
import * as light from '@/themes/light.json';

const themes = {
  dark: dark['default'],
  light: light['default'],
  brand: brand['default'],
  gold: gold['default'],
};

export default themes;
