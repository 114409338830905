import { FlexColumn } from '@/components/library/basic-styled-components';
import { ImportantText } from '@/components/library/typography/text';
import WebsiteLink from '@/components/library/website-link';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: ${({ $fixedWidth }) => ($fixedWidth ? '0 0 250px' : '1 0 0')};
  overflow-x: hidden;

  .username {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    gap: 8px;
    align-items: center;
  }

  @media (max-width: 700px) {
    flex: 1;
    text-align: ${({ $fixedWidth }) => ($fixedWidth ? 'left' : 'center')};

    .username {
      align-self: ${({ $fixedWidth }) =>
        $fixedWidth ? 'flex-start' : 'center'};
    }
  }
`;

const ProfileDetails = ({ user, fixedWidth }) => {
  return (
    <Wrapper $fixedWidth={fixedWidth}>
      <FlexColumn $gap="8px">
        <div className="username">
          <ImportantText>{user.username}</ImportantText>
        </div>
        {user.website && <WebsiteLink website={user.website} />}
        {user.contact && <div className="contact">{user.contact}</div>}
        <div className="bio">{user.bio}</div>
      </FlexColumn>
    </Wrapper>
  );
};

export default ProfileDetails;
