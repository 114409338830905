import bardAPI from '@/bard-api';
import {
  FlexColumnCenter,
  FlexRowGap,
  FlexRowSpace,
} from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import TertiaryButton from '@/components/library/buttons/tertiary-button';
import AddToCollabWrapper from '@/components/library/collaborations/add-to-collab-wrapper';
import {
  FieldHighlight,
  FieldSubText,
  Text,
} from '@/components/library/typography/text';
import Tags from '@/components/tags';
import { resetFrontPage } from '@/store/landing';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Folder, Upload } from 'react-feather';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { Form, TextArea } from 'semantic-ui-react';
import SecondaryButton from './library/buttons/secondary-button';
import PostingsList from './postings-list';

const UPLOAD_NEW = 0;
const UPLOAD_EXISTING = 1;

const STEP_1 = 0;
const STEP_2 = 1;

const AddLayout = ({
  forceUploadNew,
  type,
  collab,
  getCollabData,
  handleCloseModal,
  inModal,
  resetPage,
  showWriting = false,
}) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);
  const [formErrors, setFormErrors] = useState({});
  const [title, setTitle] = useState('');
  const [about, setAbout] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(
    'Try dragging a file here or click to select a file to upload',
  );
  const [writing, setWriting] = useState('');
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [filePreview, setFilePreview] = useState('');
  const [step, setStep] = useState(0);
  const [approach, setApproach] = useState(0);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [selected, setSelected] = useState('');
  const { id: collectionId } = useParams();
  const dispatch = useDispatch();

  // Is this enough state elliott?

  useEffect(() => {
    if (forceUploadNew) {
      setStep(STEP_2);
      setApproach(UPLOAD_NEW);
    }
  }, [forceUploadNew]);

  const onDrop = async (acceptedFiles) => {
    setFormErrors((prevState) => {
      const { file, ...newState } = prevState;
      return newState;
    });
    const file = acceptedFiles[0];

    if (!file) {
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      setFormErrors({
        ...formErrors,
        file: 'File selection failed. Files must be smaller than 10MB.',
      });
      return;
    }

    setFile(file);
    setFileName(file.name);

    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      type === 'audios'
        ? {
            'audio/*': [],
          }
        : {
            'image/*': [],
          },
    maxFiles: 1,
    onDrop: onDrop,
    onError: (err) =>
      setFormErrors({ ...formErrors, file: 'File upload failed.' }),
    onDropRejected: (err) =>
      setFormErrors({ ...formErrors, file: 'File upload failed.' }),
  });

  const setupApproach = async (approach) => {
    let data = [];
    if (approach === UPLOAD_EXISTING) {
      const response = await bardAPI.get(
        `${type}?user=${currentUser.id}&offset=0&limit=4`,
      );
      data = response.data.results;
      setTotalData(response.data.count);
    }

    setApproach(approach);
    setData(data);
    setStep(STEP_2);
  };

  const createCollabWithNewData = async () => {
    // Create generic collab data object
    const collabData = {
      name: title,
      about: about,
    };

    if (writing) {
      collabData['writing'] = writing;
    }
    if (file) {
      collabData['file_data'] = file;
    }
    if (collab) {
      collabData['collab'] = collab.id;
    }
    // Only add collection id if no collab selected
    if (collectionId && !collab) {
      collabData['collection'] = collectionId;
    }

    // Cast to FromData
    const formData = new FormData();
    const keys = Object.keys(collabData);
    keys.forEach((key) => {
      if (collabData[key] !== undefined) {
        formData.append(key, collabData[key]);
      }
    });

    tags.forEach((tag) => {
      formData.append('new_tags', tag);
    });

    setFormErrors({});
    let newFormErrors = {};
    let error = false;
    if (title === '') {
      error = true;
      newFormErrors['title'] = 'Title is required.';
    }
    if (title.length > 60) {
      error = true;
      newFormErrors['title'] = 'Title is limited to 60 characters.';
    }
    if (!('file_data' in collabData) && !showWriting) {
      error = true;
      newFormErrors['file'] = "Don't forget to select your work.";
    }
    if (file && file.size > 10 * 1024 * 1024) {
      error = true;
      newFormErrors['file'] = 'Files must be smaller than 10MB.';
    }
    if (showWriting && !writing) {
      error = true;
      newFormErrors['writing'] = "Don't forget to write your work.";
    }
    setFormErrors(newFormErrors);
    if (error) {
      return;
    }

    return await bardAPI({
      method: 'post',
      url: `${type}/`,
      data: formData,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    try {
      let newCollab = null;
      // Selecting existing art
      if (selected) {
        const response = await bardAPI({
          method: 'post',
          url: `collabs/`,
          data: {
            collab: collab.id,
            post_type: type,
            selected: selected,
          },
        });
        newCollab = response.data.id;
      } else {
        const response = await createCollabWithNewData();
        newCollab = response.data.collab;
      }

      if (handleCloseModal) {
        handleCloseModal();
      }
      dispatch(resetFrontPage());

      navigate({
        pathname: `/${newCollab}`,
      });
      getCollabData();
    } catch (error) {
      setLoading(false);
      uRequestHandler(error);
    }
  };

  const getPage = async (offsetChange) => {
    setLoading(true);
    const newOffset = offset + offsetChange;

    setOffset(newOffset);
    const response = await bardAPI.get(
      `${type}/?user=${currentUser.id}&offset=${4 * newOffset}&limit=4`,
    );

    setTotalData(response.data.count);
    setData(response.data.results);
    setLoading(false);
  };

  return (
    <AddToCollabWrapper $modal={inModal}>
      {step === STEP_1 ? (
        <div className="swap-button-wrapper">
          <PrimaryButton
            className="btn"
            onClick={() => setupApproach(UPLOAD_NEW)}
          >
            <Upload />
            Upload a New Piece
          </PrimaryButton>
          <PrimaryButton
            className="btn"
            onClick={() => setupApproach(UPLOAD_EXISTING)}
          >
            <Folder />
            Choose one of your existing pieces
          </PrimaryButton>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="add-to-collab-loading">
              <div>Uploading</div>
              <BeatLoader />
            </div>
          ) : (
            <>
              {approach === UPLOAD_NEW ? (
                <div>
                  <Form>
                    <Form.Field>
                      <label>Title</label>
                      <input
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      {'title' in formErrors && (
                        <FieldHighlight>{formErrors['title']}</FieldHighlight>
                      )}
                    </Form.Field>

                    <Form.Field>
                      <label>Description</label>
                      <TextArea
                        placeholder="About your artwork"
                        name="about"
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </Form.Field>

                    {showWriting ? (
                      <Form.Field>
                        <label>Writing</label>
                        <ReactQuill
                          name="writing"
                          theme="snow"
                          modules={{
                            toolbar: [
                              ['bold', 'italic', 'underline', 'strike'],
                            ],
                          }}
                          formats={['bold', 'italic', 'underline', 'strike']}
                          placeholder="A poem, a backstory, or a tale of adventure?"
                          value={writing}
                          onChange={setWriting}
                        />
                        {'writing' in formErrors && (
                          <FieldHighlight>
                            {formErrors['writing']}
                          </FieldHighlight>
                        )}
                      </Form.Field>
                    ) : (
                      <Form.Field>
                        <label>File*</label>

                        <div {...getRootProps({ className: 'drop-field' })}>
                          <input {...getInputProps()} />
                          {filePreview && type === 'visuals' ? (
                            <img src={filePreview} alt="icon" height="200" />
                          ) : (
                            <FlexColumnCenter $gap="8px">
                              <Text>{fileName}</Text>
                              <TertiaryButton className="btn">
                                {file ? 'Select New File' : 'Select File'}
                              </TertiaryButton>
                            </FlexColumnCenter>
                          )}
                        </div>
                        <FlexRowSpace>
                          {'file' in formErrors ? (
                            <FieldHighlight>
                              {formErrors['file']}
                            </FieldHighlight>
                          ) : (
                            <p />
                          )}
                          <FieldSubText>
                            Maximum file size is 10MB. Recommend file type is{' '}
                            {type === 'visuals' ? 'jpg' : 'mp3'}.
                          </FieldSubText>
                        </FlexRowSpace>
                      </Form.Field>
                    )}

                    <Tags updateTags={setTags} tags={tags} />
                  </Form>
                  <div className="add-to-collab-footer">
                    <SecondaryButton onClick={() => resetPage()}>
                      Go back
                    </SecondaryButton>
                    <div className="footer-actions">
                      <PrimaryButton
                        className="btn"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <PostingsList
                    endpoint={type}
                    data={data}
                    selectable
                    singleSelection={true}
                    setSelected={(item) => setSelected(item)}
                    selected={selected}
                  />
                  <div className="add-to-collab-footer">
                    <SecondaryButton onClick={() => resetPage()}>
                      Go back
                    </SecondaryButton>
                    <FlexRowGap $gap="4px">
                      {offset > 0 && (
                        <SecondaryButton
                          className="btn"
                          onClick={() => getPage(-1)}
                        >
                          Previous Page
                        </SecondaryButton>
                      )}
                      {offset + 1 * 4 < totalData && (
                        <SecondaryButton
                          className="btn"
                          onClick={() => getPage(1)}
                        >
                          Next Page
                        </SecondaryButton>
                      )}
                      <PrimaryButton
                        className="btn"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save
                      </PrimaryButton>
                    </FlexRowGap>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </AddToCollabWrapper>
  );
};

export default AddLayout;
