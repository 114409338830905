import styled from 'styled-components';

const SIZES = {
  null: '32px',
  tn: '24px',
  sm: '32px',
  md: '80px',
  lg: '140px',
};

const Wrapper = styled.div`
  img {
    height: ${({ size }) => SIZES[size]};
    width: ${({ size }) => SIZES[size]};
    border-radius: ${({ size }) => SIZES[size]};
    object-fit: cover;
    border: ${({ $pro, theme }) =>
      $pro
        ? `2px solid ${theme.colors.highlight}`
        : `2px solid ${theme.colors.card.border}`};
  }
`;

const UserImgDefault = ({ user, size }) => {
  return (
    <Wrapper $pro={user.has_pro_features} size={size}>
      {user.profile_img ? (
        <img
          src={`https://${import.meta.env.VITE_APP_S3}/${user.profile_img}`}
          className="profile-image"
          alt="Unsuccessfully loaded."
        />
      ) : (
        <img
          src={`https://${import.meta.env.VITE_APP_S3}/profile/avatar.png`}
          className="profile-image"
          alt="Unsuccessfully loadded."
        />
      )}
    </Wrapper>
  );
};

export default UserImgDefault;
