import bardAPI from '@/bard-api';
import PrimaryButton from '@/components/library/buttons/primary-button';
import TextButton from '@/components/library/buttons/text-button';
import WordTagging from '@/components/library/WordTagging';
import { COMMENT_REPORT, uRequestHandler } from '@/utils';
import { useState } from 'react';
import { CornerDownRight } from 'react-feather';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import CommentsWrapper from './comments/comments-wrapper';
import { FlexColumn } from './library/basic-styled-components';
import ReportModal from './modals/report-modal';
import UserHeader from './user-header';

const Comment = ({
  comment,
  updateCommentsList,
  collab,
  post,
  depth,
  parentCommentId,
  removeData,
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [nestedComment, setNestedComment] = useState('');
  const [showNewCommentInput, setShowNewCommentInput] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const getTagsFromComment = (username, comment) => {
    const tagList = [];
    let tagString = '';
    if (username !== currentUser.username) {
      tagList.push([`@${username}`]);
      tagString = `@${username} `;
    }

    const words = comment.split(' ');
    for (let i = 0; i < words.length; i += 1) {
      const word = words[i];
      const alreadyTagged = tagList.indexOf(word) > -1;
      if (word.charAt(0) === '@' && !alreadyTagged) {
        if (word.substr(1) !== currentUser.username) {
          tagString += `${word} `;
          tagList.push(word);
        }
      }
    }

    return tagString;
  };

  const openReplyDialog = () => {
    setShowNewCommentInput(!showNewCommentInput);
    const nestedComment = getTagsFromComment(
      comment.user.username,
      comment.comment,
    );
    setNestedComment(nestedComment);
  };

  const handleSubmit = () => {
    const newComment = {
      comment: nestedComment,
      user_id: currentUser.id,
      collab: collab ? collab.id : null,
      post: post ? post.id : null,
      parent_comment_id: parentCommentId,
    };

    bardAPI
      .post(`comments/`, newComment)
      .then((result) => {
        setNestedComment('');
        setShowNewCommentInput(false);

        updateCommentsList(result.data);
      })
      .catch((error) => {
        uRequestHandler(error);
      });
  };

  const renderComment = () => {
    return (
      <WordTagging
        text={comment.comment}
        linkTo={(word) => `/profile/${word.substr(1).toLowerCase()}`}
      />
    );
  };

  const isChildComment = depth === 1;
  const sortedComments = comment.child_comments
    ? comment.child_comments.sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];
  const isLoggedIn = currentUser.id;
  return (
    <CommentsWrapper>
      <div className="comment">
        {comment && !comment.false_delete ? (
          <FlexColumn name={comment.id} $gap="8px">
            <UserHeader
              user={comment.user}
              reportObject={comment}
              reportCategory={COMMENT_REPORT}
              createdDate={comment.created_date}
              removeData={removeData}
              modalHeader={true}
            />

            <div className="comment-comment">{renderComment()}</div>

            {isLoggedIn && (
              <div
                className={`comment-footer${
                  showNewCommentInput ? ' reply-open' : ''
                }`}
              >
                <div className="comment-reply-icon">
                  <CornerDownRight />
                </div>
                <TextButton onClick={openReplyDialog}>Reply</TextButton>
              </div>
            )}
          </FlexColumn>
        ) : (
          <>
            {comment &&
              comment.false_delete &&
              comment.child_comments.length > 0 && (
                <div className="comment-deleted">
                  This comment has been deleted.
                </div>
              )}
          </>
        )}

        {sortedComments.map((childComment) => (
          <div
            key={childComment.id}
            className={isChildComment ? 'child-comment' : ''}
          >
            <Comment
              comment={childComment}
              openReplyDialog={() => setShowReportModal(true)}
              updateCommentsList={updateCommentsList}
              collab={collab}
              post={post}
              depth={depth + 1}
              parentCommentId={parentCommentId}
              removeData={removeData}
            />
          </div>
        ))}

        {showNewCommentInput ? (
          <div className={'reply-dialog ' + (depth <= 1 ? 'reply-nested' : '')}>
            <Form>
              <Form.Field>
                <textarea
                  placeholder="Leave a comment..."
                  name="nestedComment"
                  value={nestedComment}
                  onChange={(e) => setNestedComment(e.target.value)}
                />
              </Form.Field>
              <PrimaryButton className="btn" onClick={handleSubmit}>
                Comment
              </PrimaryButton>
            </Form>
          </div>
        ) : (
          <span />
        )}

        <ReportModal
          category={COMMENT_REPORT}
          reportObject={comment}
          showModal={showReportModal}
        />
      </div>
    </CommentsWrapper>
  );
};

export default Comment;
