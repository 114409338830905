import styled from 'styled-components';

const Progress = styled.div`
  background: ${({ theme }) => theme.colors.highlight};
  flex: ${({ flex }) => flex};
`;

const Bar = styled.div`
  background: ${({ theme }) => theme.colors.card.background};
  flex: ${({ flex }) => flex};
`;

const ProgressBar = ({ progress, total }) => {
  return (
    <div
      style={{
        display: 'flex',
        height: 4,
        width: '100%',
      }}
    >
      <Progress flex={progress} />
      <Bar flex={total} />
    </div>
  );
};

export default ProgressBar;
