import bardAPI from '@/bard-api';
import PrimaryButton from '@/components/library/buttons/primary-button';
import TextButton from '@/components/library/buttons/text-button';
import Modal from '@/components/library/modal-override';
import { uRequestHandler } from '@/utils';
import { X } from 'react-feather';

const ConfirmUnsubscribe = ({ showModal, setModalVisibility }) => {
  const cancelSubscription = async () => {
    try {
      await bardAPI.delete(`cancel-subscription/`);
      window.location.reload();
    } catch (error) {
      uRequestHandler(error);
    }
  };

  return (
    <Modal size="tiny" open={showModal}>
      <Modal.Header>
        Unsubscribe
        <X onClick={() => setModalVisibility(false)} className="modal-close" />
      </Modal.Header>
      <Modal.Content>
        <p className="basic-text">
          Are you sure you want to unsubscribe from the Bard Pro Plan?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <TextButton onClick={() => setModalVisibility(false)}>
          Cancel
        </TextButton>
        <PrimaryButton className="btn" onClick={cancelSubscription}>
          Unsubscribe
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmUnsubscribe;
