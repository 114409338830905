import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import UserImgDefault from './user-img-default';

const SocialPostsAllowedDot = styled.div`
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const UserImg = ({ user }) => {
  const currentUser = useSelector((state) => state.currentUser);

  const showSocialDot = user.social_posts_allowed && currentUser.is_admin;
  return (
    <Link to={`/profile/${user.username}`}>
      <UserImgDefault user={user} size="sm" />
      {showSocialDot && <SocialPostsAllowedDot />}
    </Link>
  );
};

export default UserImg;
