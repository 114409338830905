import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
  gap: 28px;

  .profile-picture {
    position: relative;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }
`;

export default Wrapper;
