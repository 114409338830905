import bardAPI from '@/bard-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getCurrentUser = createAsyncThunk(
  'users/getCurrentUser',
  async () => {
    try {
      const request = await bardAPI.get(`me/`);
      return request.data.user;
    } catch (err) {
      bardAPI.defaults.headers.common.Authorization = ``;
    }
  },
);

export const fetchUserAfterUpdate = createAsyncThunk(
  'users/fetchUserAfterUpdate',
  async () => {
    try {
      const request = await bardAPI.get(`me/`);
      return request.data.user;
    } catch (err) {
      bardAPI.defaults.headers.common.Authorization = ``;
      window.location.reload();
    }
  },
);

const userSlice = createSlice({
  name: 'users',
  initialState: {},
  reducers: {
    logout(state) {
      localStorage.removeItem('sessionId');
      bardAPI.defaults.headers.common.Authorization = ``;
      state = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state, action) => {
      return {
        isLoading: true,
      };
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      return {};
    });
    builder.addCase(fetchUserAfterUpdate.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
