import styled from 'styled-components';

const TextButton = styled.button`
  color: ${({ theme }) => theme.colors.bodyText};
  text-transform: ${({ $upper }) => ($upper ? 'uppercase' : 'auto')};
  font-weight: 400;
`;

export const TabButton = styled.button`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.bodyText};
  text-transform: ${({ $upper }) => ($upper ? 'uppercase' : 'auto')};
  font-weight: 600;
  text-decoration: ${({ $active }) => ($active ? 'underline' : 'none')};
`;

export default TextButton;
