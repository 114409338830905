import { X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SecondaryButton from './buttons/secondary-button';
import { PageHeader } from './typography/header';

const Wrapper = styled.div`
  height: 38px;
  display: flex;
  width: 104%;
  margin: 0 0 80px -2%;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
  gap: 18px;

  @media (max-width: 700px) {
    width: 100%;
    margin: 0 0 80px 0;
  }
`;

const PageModalHeader = ({ location, title, close }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <PageHeader>{title}</PageHeader>
      {location && (
        <SecondaryButton
          className="btn"
          onClick={() => navigate(location)}
          $round
        >
          <X />
        </SecondaryButton>
      )}
      {close && (
        <SecondaryButton className="btn" onClick={() => close()} $round>
          <X />
        </SecondaryButton>
      )}
    </Wrapper>
  );
};

export default PageModalHeader;
