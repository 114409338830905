import styled from 'styled-components';

const ArtistSearchWrapper = styled.div`
  margin: 20px 0;
  .search-wrapper {
    padding: 20px;
    margin: auto;
    width: 100%;
    max-width: 600px;
    display: flex;
    gap: 1rem;
    align-items: center;
    h2 {
      margin: auto;
      text-align: center;
    }
    input.search-bar {
      height: 42px;
      outline: none;
      width: 100%;
      border: ${({ theme }) => `1px solid ${theme.colors.border}`};
      border-radius: 6px;
      padding: 0 12px;
    }
  }
`;

export default ArtistSearchWrapper;
