import styled from 'styled-components';

export const PageContainer = styled.div`
  min-height: 100vh;
  position: relative;
  padding: ${({ $modal }) => ($modal ? '60px 0 0 0' : '0px')};
  display: flex;
  flex-direction: column;
`;

const contentWidth = {
  regular: '1280px',
  full: '2000px',
  modal: '820px',
  form: '420px',
};
export const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : '24px')};
  max-width: ${({ $type }) => ($type ? contentWidth[$type] : '1280px')};
  padding: ${({ $footerpadding }) =>
    $footerpadding ? '0 60px 180px 60px' : '0px 60px 120px 60px'};

  @media (max-width: 700px) {
    padding: ${({ $footerpadding }) =>
      $footerpadding ? '0 20px 180px 20px' : '0px 20px 120px 20px'};
  }
`;

export const PageFormWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.card.background};
  padding: 30px;
  border-radius: ${({ $radius }) => ($radius ? $radius : '6px')};
`;

export const BottomSection = styled.div`
  flex-grow: 1;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.card.background};
  border-top: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  padding-bottom: 180px;
  .collab-about {
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    button {
      align-self: baseline;
    }
    .collab-about-sharing {
      display: flex;
      gap: 16px;
    }
  }
`;
