import styled from 'styled-components';

const SettingsContent = styled.div`
  width: 100%;
  .settings-content-section {
    margin-bottom: 36px;
    .settings-content-section-title {
      margin-bottom: 16px;
      display: flex;
      Button {
        margin-left: auto;
      }
    }
    .settings-section-description {
      margin-bottom: 12px;
      width: 100%;
      max-width: 700px;
      color: ${({ theme }) => theme.colors.subtext};
    }
    .settings-content-block {
      padding: 20px 0;
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
      display: flex;
      align-items: center;
      justify-content: space-between;
      .membership-option {
        background-color: ${({ theme }) => theme.colors.card.background};
        border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
        padding: 20px;
        width: 300px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: left;
        button {
          margin-top: 16px;
          align-self: center;
          justify-self: flex-end;
        }
      }
      &:last-of-type {
        border-bottom: 0px;
      }
    }
  }
`;

export default SettingsContent;
