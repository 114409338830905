import PrimaryButton from '@/components/library/buttons/primary-button';
import TextButton from '@/components/library/buttons/text-button';
import Modal from '@/components/library/modal-override';
import { X } from 'react-feather';

const ConfirmModal = ({
  showModal,
  setModalVisibility,
  action,
  header,
  confirmation,
  actionText,
}) => {
  return (
    <Modal size="tiny" open={showModal}>
      <Modal.Header>
        {header}
        <X onClick={() => setModalVisibility(false)} className="modal-close" />
      </Modal.Header>
      <Modal.Content>
        <p className="basic-text">{confirmation}</p>
      </Modal.Content>
      <Modal.Actions>
        <TextButton onClick={() => setModalVisibility(false)}>
          Cancel
        </TextButton>
        <PrimaryButton className="btn" onClick={action}>
          {actionText}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmModal;
