import AudioPlayer from '@/components/audio-player/index';
import { useNavigate } from 'react-router';

const AudioPost = ({ selectable, setSelected, post }) => {
  const navigate = useNavigate();

  return (
    <div className="audio-container">
      {selectable ? (
        <div className="selectable-content">
          <AudioPlayer
            id={`collab-audio-${post.id}`}
            audio={post}
            onTitleClicked={setSelected}
          />
        </div>
      ) : (
        <AudioPlayer
          id={`collab-audio-${post.id}`}
          audio={post}
          onTitleClicked={() => navigate(`/audio/${post.id}`)}
        />
      )}
    </div>
  );
};

export default AudioPost;
