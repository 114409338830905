import axios from 'axios';

const bardAPI = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API}/`,
  timeout: 2000,
  headers: {
    Accept: 'application/json',
  },
});

export default bardAPI;
