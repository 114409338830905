import { ItemHeader } from '@/components/library/typography/header';
import { FootNote, SubText } from '@/components/library/typography/text';
import styled from 'styled-components';

export const CollabBlockHeader = styled(ItemHeader)`
  margin-bottom: 12px;
`;

export const AboutText = styled(SubText)`
  color: ${({ theme }) => theme.colors.subtext};
  margin-bottom: 12px;
`;

export const PostedDate = styled(FootNote)`
  text-align: right;
  margin-top: 4px;
`;
