export const POST_REPORT = 1;
export const COMMENT_REPORT = 2;
export const VISUAL_REPORT = 3;
export const AUDIO_REPORT = 4;
export const WRITING_REPORT = 5;
export const USER_REPORT = 6;

export const MEMBER_STATUS_ACTIVE = 2;
export const MEMBER_STATUS_BLOCKED = 3;
export const MEMBER_STATUS = {
  [MEMBER_STATUS_ACTIVE]: 'Active',
  [MEMBER_STATUS_BLOCKED]: 'Blocked',
};

export function uRequestHandler(error) {
  if (error.response && error.response.status === 401) {
    // history.push('/');
  } else if (error.response && error.response.status === 404) {
    // if (history) {
    //   history.push('/notfound');
    // }
  }

  return error;
}

export function checkStatus2(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export function getLength(length, liked, reverse) {
  if (liked || (liked && reverse)) {
    return length + 1;
  }
  if (liked && reverse) {
    return length - 1;
  }
  return length;
}

export function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
