import styled from 'styled-components';

export const SocialPostsAllowedDot = styled.div`
  width: 18px;
  height: 18px;
  background-color: green;
  border-radius: 20px;
  position: absolute;
  top: ${({ $offset }) => ($offset ? $offset : '10px')};
  left: ${({ $offset }) => ($offset ? $offset : '10px')};
`;

export const ProFlag = styled.div`
  background-color: ${({ theme }) => theme.colors.highlight};
  border-radius: 4px;
  padding: 2px 6px;
  color: ${({ theme }) => theme.colors.card.background};
  position: absolute;
  top: 130px;
  left: 46px;
`;

export const ProfileRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;

  @media (max-width: 700px) {
    align-items: center;
  }
`;
