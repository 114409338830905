import styled from 'styled-components';

const ArtistResult = styled.div`
  padding: 16px;
  display: flex;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  gap: 20px;
  align-items: center;
  .user-content-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    .user-content {
      display: flex;
      gap: 12px;
      .content-wrapper {
        height: 180px;
        a {
          display: block;
        }
      }
      img {
        height: 180px;
        border-radius: 6px;
      }
      .story {
        width: 220px;
        height: 180px;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 800px) {
    .user-content-wrapper {
      display: none;
    }
  }
`;

export default ArtistResult;
