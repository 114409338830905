import { SectionHeader } from '@/components/library/typography/header';
import { Text } from '@/components/library/typography/text';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  margin: 80px 0;

  p {
    max-width: 600px;
    margin: auto;
  }
`;

const PageIntro = ({ intro, title }) => {
  return (
    <Wrapper>
      <SectionHeader $margin="0 0 24px 0">{title}</SectionHeader>
      <Text>{intro}</Text>
    </Wrapper>
  );
};

export default PageIntro;
