import styled from 'styled-components';

const Wrapper = styled.a`
  line-height: 0px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const IconLink = ({ website, icon, type }) => {
  const typeMap = {
    instagram: 'https://www.instagram.com/',
    twitter: 'https://twitter.com/',
  };

  const link = website.includes('https://')
    ? website
    : `${typeMap[type]}${website}`;

  return (
    <Wrapper
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="icon"
    >
      {icon}
    </Wrapper>
  );
};

export default IconLink;
