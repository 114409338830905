import { loadStripe } from '@stripe/stripe-js';
import { useDropzone } from 'react-dropzone';
import BarLoader from 'react-spinners/BarLoader';
import styled, { useTheme } from 'styled-components';

import bardAPI from '@/bard-api';
import Footer from '@/components/footer';
import PrimaryButton from '@/components/library/buttons/primary-button';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import TertiaryButton from '@/components/library/buttons/tertiary-button';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import InlineCheckbox from '@/components/library/forms/inline-checkbox';
import InlineInput from '@/components/library/forms/inline-input';
import InlineLabelButton from '@/components/library/forms/inline-label-button';
import {
  ItemHeader,
  SectionHeader,
} from '@/components/library/typography/header';
import { FieldHighlight, SubText } from '@/components/library/typography/text';
import ConfirmDelete from '@/components/modals/confirm-delete';
import ConfirmUnsubscribe from '@/components/modals/confirm-unsubscribe';
import Navbar from '@/components/navbar/index';
import SubscribeEmail from '@/components/subscribe-email';
import UserImgDefault from '@/components/user-img-default';
import SettingsContent from '@/containers/account/components/settings-content';
import { fetchUserAfterUpdate, getCurrentUser } from '@/store/user';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentProgressBar, {
  PaymentProgressTitles,
} from './components/payment-progress-bar';
import ThemeSelector from './components/theme-selector';

const Memberships = styled.div`
  width: 100%;
  display: flex;
  gap: 18px;
  align-items: stretch;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_KEY);
const PLAN_FREE = 'free';
const PLAN_PRO = 'pro';

const Settings = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const [subscribeMessage, setSubscribeMessage] = useState('');
  const [proCount, setProCount] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmUnsubscribe, setShowConfirmUnsubscribe] = useState(false);
  const [uploading, setUploading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    getUsageInfo();
  }, []);

  const getUsageInfo = async () => {
    const stats = await bardAPI.get(`usage_stats/`);
    const count = stats.data.pro_count;
    setProCount(count);
  };

  const patchUser = async (obj) => {
    try {
      await bardAPI.put(`users/${currentUser.id}/`, obj);
      dispatch(fetchUserAfterUpdate());
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const downloadUserData = async () => {
    try {
      const result = await bardAPI.get(`user_data`);

      const objectData = result.data;
      const filename = `${currentUser.username}BardData.json`;
      const contentType = 'application/json;charset=utf-8;';
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob(
          [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
          { type: contentType },
        );
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement('a');
        a.download = filename;
        a.href = `data:${contentType},${encodeURIComponent(
          JSON.stringify(objectData),
        )}`;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const fetchCheckoutSession = async () => {
    try {
      const result = await bardAPI.get(`checkout-session/`);
      return result.data;
    } catch (error) {
      uRequestHandler(error);
      return {};
    }
  };

  const fetchPaymentSession = async () => {
    try {
      const result = await bardAPI.get(`payment-session/`);
      return result.data;
    } catch (error) {
      uRequestHandler(error);
      return {};
    }
  };

  const subscribe = async () => {
    // Call your backend to create the Checkout session.
    const session = await fetchCheckoutSession();
    // When the customer clicks on the button, redirect them to Checkout.
    if (session && session.id) {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      setSubscribeMessage(error.message);
    } else {
      setSubscribeMessage('Something went wrong, try refreshing');
    }
  };

  const onDrop = async (acceptedFiles) => {
    const profileImg = acceptedFiles[0];

    const formData = new FormData();
    formData.append('profile', profileImg);

    let filePath = '';
    try {
      setUploading(true);
      const response = await bardAPI.post(`users/upload/`, formData);
      filePath = response.data;
      setUploading(false);
    } catch (error) {
      setUploading(false);
      uRequestHandler(error);
      return;
    }

    try {
      await bardAPI.put(`users/${currentUser.id}/`, {
        profile_img: filePath,
      });

      dispatch(getCurrentUser());
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop: onDrop,
  });

  const updatePaymentDetails = async () => {
    // Call your backend to create the Checkout session.
    const session = await fetchPaymentSession();
    // When the customer clicks on the button, redirect them to Checkout.
    if (session && session.id) {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      setSubscribeMessage(error.message);
    } else {
      setSubscribeMessage('Something went wrong, try refreshing');
    }
  };

  const monthlyRevenue = proCount * 5;

  const selectedProPlan = currentUser.selected_plan === PLAN_PRO;
  const activeSubscription =
    new Date(currentUser.subscription_end) > new Date();
  const subscriptionEnd = new Date(
    currentUser.subscription_end,
  ).toLocaleDateString();
  return (
    <PageContainer>
      <Navbar />
      {currentUser ? (
        <ContentContainer $footerpadding>
          <SettingsContent>
            <div className="settings-content-section">
              <SectionHeader>Profile</SectionHeader>
              <div className="settings-content-block">
                <UserImgDefault user={currentUser} size="md" />
                {uploading ? (
                  <BarLoader color={theme.colors.highlight} />
                ) : (
                  <TertiaryButton className="btn">
                    <div {...getRootProps({ className: 'drop-field-button' })}>
                      <input {...getInputProps()} />
                      <p>Select New Photo</p>
                    </div>
                  </TertiaryButton>
                )}
              </div>
              <InlineInput
                important
                placeholder="username"
                actionText="Edit username"
                onSubmit={(value) => {
                  patchUser({ username: value });
                }}
                checkForErrors={async (value) => {
                  const validUsername = new RegExp('^[a-z0-9]+$', 'i');
                  if (!validUsername.test(value)) {
                    return 'Usernames can only have letters and numbers';
                  }
                  const result = await bardAPI.get(`users/?username=${value}`);
                  if (result.data.length > 0)
                    return 'Username is already taken';
                  return '';
                }}
                defaultValue={currentUser.username}
              />
              <InlineInput
                textArea
                placeholder="Add a little bit about yourself"
                actionText="Edit bio"
                onSubmit={(value) => {
                  patchUser({ bio: value });
                }}
                defaultValue={currentUser.bio}
              />
              <InlineInput
                placeholder="Add a link to your website or portfolio"
                actionText="Edit website"
                onSubmit={(value) => {
                  patchUser({ website: value });
                }}
                defaultValue={currentUser.website}
              />
              <InlineCheckbox
                label={
                  'Bard can post my art on their social medias, with credit'
                }
                onSubmit={(value) => {
                  patchUser({ social_posts_allowed: value });
                }}
                defaultValue={currentUser.social_posts_allowed}
              />
            </div>

            <div className="settings-content-section">
              <SectionHeader $margin="0 0 12px 0">Contact</SectionHeader>
              <div className="settings-section-description">
                This email will show up on your profile so that people can
                contact you.
              </div>
              <InlineInput
                placeholder="Enter an email that people can contact you with"
                actionText="Edit email"
                onSubmit={(value) => {
                  patchUser({ contact: value });
                }}
                defaultValue={currentUser.contact}
              />
            </div>

            <div className="settings-content-section">
              <SectionHeader $margin="0 0 12px 0">Emails</SectionHeader>
              <SubscribeEmail />
            </div>

            <div className="settings-content-section">
              <SectionHeader $margin="0 0 12px 0">Theme</SectionHeader>
              <div className="settings-section-description">
                Pick a theme that resonates with you to make this place feel
                like home.
              </div>
              <ThemeSelector patchUser={patchUser} />
            </div>

            <div className="settings-content-section">
              <SectionHeader $margin="0 0 12px 0">Membership</SectionHeader>
              <div className="settings-section-description">
                We aim to be as transparent as possible with everything,
                including our revenue and expenses. Sometimes our running costs
                change slightly.
              </div>
              <PaymentProgressTitles>
                <div style={{ flex: 1 }}>Running costs covered</div>
              </PaymentProgressTitles>
              <PaymentProgressBar>
                <div style={{ flex: 1 }}>
                  <div style={{ flex: monthlyRevenue }} />
                  <div style={{ flex: 500 - monthlyRevenue }} />
                </div>
              </PaymentProgressBar>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <SubText color={theme.colors.subtext}>$0 / month</SubText>
                <SubText color={theme.colors.subtext}>$500 / month</SubText>
              </div>

              <div style={{ marginTop: '24px' }}>
                {activeSubscription && (
                  <SubText $margin="8px 0">
                    Your active paid subscription ends on {subscriptionEnd}
                  </SubText>
                )}
              </div>

              <div className="settings-content-block">
                <Memberships>
                  <div className="membership-option">
                    <SectionHeader>$0</SectionHeader>
                    <ItemHeader>Free</ItemHeader>
                    <div>
                      <SubText>Ad free timeline</SubText>
                      <SubText>Chronological timeline</SubText>
                      <SubText>Collaborating features</SubText>
                      <SubText>All the essentials</SubText>
                    </div>
                    {selectedProPlan ? (
                      <PrimaryButton
                        role="link"
                        onClick={() => setShowConfirmUnsubscribe(true)}
                        className="btn status-button enabled"
                      >
                        <span>Go Free</span>
                      </PrimaryButton>
                    ) : (
                      <SecondaryButton className="btn status-button current">
                        <span>Current Plan</span>
                      </SecondaryButton>
                    )}
                  </div>
                  <div className="membership-option">
                    <SectionHeader>$5/month</SectionHeader>
                    <ItemHeader>Pro</ItemHeader>
                    <div>
                      <SubText>All free features</SubText>
                      <SubText>Profile flair</SubText>
                      <SubText>More to come</SubText>
                    </div>
                    {selectedProPlan ? (
                      <SecondaryButton className="btn status-button current">
                        <span>Current Plan</span>
                      </SecondaryButton>
                    ) : (
                      <PrimaryButton
                        role="link"
                        onClick={(e) => subscribe(e)}
                        className="btn status-button enabled"
                      >
                        <span>Sign Up!</span>
                      </PrimaryButton>
                    )}
                  </div>
                </Memberships>
              </div>

              {subscribeMessage.length > 0 && (
                <FieldHighlight>{subscribeMessage}</FieldHighlight>
              )}

              <InlineLabelButton
                label="Payment Method"
                onClick={(e) => updatePaymentDetails(e)}
                actionText="Update billing"
                disabled={!selectedProPlan}
              />
              {/*https://stripe.com/docs/payments/checkout/subscriptions/updating*/}
            </div>

            <div className="settings-content-section">
              <SectionHeader $margin="0 0 12px 0">Account</SectionHeader>
              <InlineLabelButton
                label="Download your personal information in a JSON file"
                onClick={(e) => downloadUserData(e)}
                actionText="Download"
              />
              <InlineLabelButton
                label="Permanently delete your account - "
                subText={<span className="danger">Danger Zone</span>}
                onClick={() => setShowConfirmDelete(true)}
                actionText="Delete account"
              />
            </div>
          </SettingsContent>
          <ConfirmDelete
            showModal={showConfirmDelete}
            setModalVisibility={setShowConfirmDelete}
          />
          <ConfirmUnsubscribe
            showModal={showConfirmUnsubscribe}
            setModalVisibility={setShowConfirmUnsubscribe}
          />
        </ContentContainer>
      ) : (
        <span />
      )}
      <Footer />
    </PageContainer>
  );
};

export default Settings;
