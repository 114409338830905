import styled from 'styled-components';

const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const IconToggle = ({ toggled, toggle, icon, fillClass }) => (
  <Wrapper
    className={toggled ? ` ${fillClass}` : ''}
    role="link"
    tabIndex={0}
    onClick={toggle}
    onKeyPress={toggle}
  >
    {icon}
  </Wrapper>
);

export default IconToggle;
