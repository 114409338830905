import styled from 'styled-components';

const EmptyBlock = styled.div`
  border-radius: 6px;
  height: ${({ $small }) => ($small ? '80px' : '140px')};
  padding: 0 18px;
  background-color: ${({ theme }) => theme.colors.card.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default EmptyBlock;
