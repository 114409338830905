import styled from 'styled-components';

const Tag = styled.div`
  background-color: ${({ theme }) => theme.colors.highlight};
  color: ${({ theme }) => theme.colors.body};
  border-radius: 6px;
  padding: 3px 6px;
  display: flex;
  gap: 8px;
  align-items: center;
  svg {
    height: 18px;
    width: 18px;
    color: ${({ theme }) => theme.colors.body};
    &:hover {
      cursor: pointer;
    }
  }
`;

export default Tag;
