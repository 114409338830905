import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Share2 } from 'react-feather';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  .collab-about-share,
  button svg {
    color: ${({ theme }) => theme.colors.subtext};
    &:hover {
      cursor: pointer;
    }
  }
`;

const SelectedBubble = styled.div`
  padding: 3px 8px;
  position: absolute;
  top: -40px;
  right: -6px;
  white-space: pre;
  background-color: ${({ theme }) => theme.colors.card.background};
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  border-radius: 21px;
  font-size: 14px;
`;

const CopyLink = ({ link }) => {
  const [copied, setCopied] = useState(false);

  const showCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Wrapper>
      {copied && <SelectedBubble>Link copied to clipboard!</SelectedBubble>}
      <CopyToClipboard text={link} onCopy={showCopy}>
        <div className="collab-about-share">
          <Share2 />
        </div>
      </CopyToClipboard>
    </Wrapper>
  );
};

export default CopyLink;
