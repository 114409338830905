import bardAPI from '@/bard-api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CollectionsList from '@/components/collections-list';
import IntroBanner from '@/components/intro-banner';
import { FlexColumn } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import { Text } from '@/components/library/typography/text';
import Navbar from '@/components/navbar/index';
import { fetchUserAfterUpdate } from '@/store/user';
import { uRequestHandler } from '@/utils';

const Collections = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const [searching, setSearching] = useState(false);
  const [offset, setOffset] = useState(1);
  const [collections, setCollections] = useState([]);
  const [totalCollections, setTotalCollections] = useState(0);

  useEffect(() => {
    const getPage = async () => {
      setSearching(true);

      try {
        const response = await bardAPI.get(
          `collections/?offset=${offset * 10}&limit=10`,
        );

        setCollections((users) => users.concat(response.data.results));
        setTotalCollections(response.data.count);
        setSearching(false);
      } catch (error) {
        setSearching(false);
        uRequestHandler(error);
      }
    };

    getPage();
  }, [offset]);

  useEffect(() => {
    const getInitialData = async () => {
      setSearching(true);
      const response = await bardAPI.get(`collections/?offset=0&limit=10`);

      setCollections(response.data.results);
      setSearching(false);
    };

    getInitialData();
  }, []);

  const trackScrolling = useCallback(() => {
    const wrappedElement = document.getElementById('collections-container');
    if (
      wrappedElement.getBoundingClientRect().bottom <=
        window.innerHeight + 700 &&
      !searching &&
      collections.length < totalCollections
    ) {
      setOffset((offset) => offset + 1);
    }
  }, [searching, collections, totalCollections]);

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return () => document.removeEventListener('scroll', trackScrolling);
  }, [trackScrolling]);

  const subscribe = async () => {
    try {
      await bardAPI.post(`email-subscription/`);
      dispatch(fetchUserAfterUpdate());
    } catch (e) {
      uRequestHandler(e);
    }
  };

  return (
    <PageContainer id="collections-container">
      <Navbar />

      <IntroBanner
        header={'Collections'}
        subHeader={
          'Collections are worlds that have been collaboratively build with the community.'
        }
      />

      <ContentContainer>
        <CollectionsList collections={collections} />
        <FlexColumn $gap="12px">
          <Text>
            In order to discourage people from all wanting to create their own
            world and to encourage artists to collaborate on worlds together we
            have disabled ability to create collections.
          </Text>
          <Text>
            Once Bard has a small thriving community we will announce some
            worlds for people to collaborate on. If there is a world that you
            would like us to create for everyone to collaborate on reach out by
            email or any of our socials: hello@bard.art.
          </Text>
          {currentUser.id && (
            <div>
              {currentUser.subscribed_newsletter ? (
                <Text>
                  You are subscribed to our newsletter and will be the first to
                  hear about upcoming worlds.
                </Text>
              ) : (
                <FlexColumn $gap="12px" $align="flex-start">
                  <Text>
                    Subscribe to our newsletter so that you can be the first to
                    hear about upcoming collections!
                  </Text>
                  <PrimaryButton className="btn" onClick={subscribe}>
                    Subscribe to newsletter
                  </PrimaryButton>
                </FlexColumn>
              )}
            </div>
          )}
        </FlexColumn>
      </ContentContainer>
    </PageContainer>
  );
};

export default Collections;
