const VisualPiece = ({ visual }) => {
  const { file_path } = visual;

  return (
    <div className="visual-container">
      <img
        src={`https://${import.meta.env.VITE_APP_S3}/${file_path}`}
        alt="Unsuccessfully loaded."
      />
    </div>
  );
};

export default VisualPiece;
