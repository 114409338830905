import PrivateRoute from '@/components/navigation/private-router';
import Settings from '@/containers/account/settings';
import CreateAccount from '@/containers/account/user-setup/index';
import Artists from '@/containers/artists/index';
import Badges from '@/containers/badges';
import Collaboration from '@/containers/collaboration/index';
import Collection from '@/containers/collection/index';
import Collections from '@/containers/collections/index';
import EditAudio from '@/containers/edit-audio';
import EditVisual from '@/containers/edit-visual';
import EditWriting from '@/containers/edit-writing';
import Following from '@/containers/following';
import Landing from '@/containers/landing/index';
import NewCollab from '@/containers/new-collab';
import NotFoundPage from '@/containers/not-found';
import Notifications from '@/containers/notifications/index';
import Profile from '@/containers/profile/index';
import SavedList from '@/containers/saved-list';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';

const Router = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Routes>
      <Route path="/profile/:username" element={<Profile />} />
      <Route path="/audio/:id" element={<EditAudio />} />
      <Route path="/visual/:id" element={<EditVisual />} />
      <Route path="/writing/:id" element={<EditWriting />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="/createaccount" element={<CreateAccount />} />
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/new/:id" element={<NewCollab />} />
      </Route>
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/new" element={<NewCollab />} />
      </Route>
      <Route path="/notfound" element={<NotFoundPage />} />
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/saved" element={<SavedList />} />
      </Route>
      <Route path="/artists" element={<Artists />} />
      <Route path="/collection/:id" element={<Collection />} />
      <Route path="/collections" element={<Collections />} />
      <Route path="/badges" element={<Badges />} />
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/following" element={<Following />} />
      </Route>
      <Route path="/:id" element={<Collaboration />} />
      <Route path="/" element={<Landing />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
