import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0px;
  justify-content: space-between;
  .filter-tag-count {
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 24px;
    color: ${({ theme }) => theme.colors.body};
    background-color: ${({ theme }) => theme.colors.highlight};
  }
  .collab-order-options {
    display: flex;
    button {
      margin: 0 14px;
    }
  }
  .collab-filters {
    position: relative;
    display: flex;
    gap: 12px;
    align-items: center;
  }

  @media (max-width: 700px) {
    .collab-filters {
      display: none;
    }
  }
`;

export default Wrapper;
