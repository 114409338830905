import Banner from '@/components/banner';
import { BannerHeader } from '@/components/library/typography/header';
import { Instructions } from '@/components/library/typography/text';

const IntroBanner = ({ header, subHeader }) => {
  return (
    <Banner>
      <div className="shell-container">
        <div className="banner-wrapper">
          <div className="banner-text">
            <BannerHeader className="banner-title">{header}</BannerHeader>
            <Instructions className="banner-secondary">
              {subHeader}
            </Instructions>
          </div>
        </div>
      </div>
    </Banner>
  );
};

export default IntroBanner;
