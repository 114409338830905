import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, redirectPath = '/' }) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
