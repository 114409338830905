import TertiaryButton from '@/components/library/buttons/tertiary-button';

const InlineLabelButton = ({
  label,
  onClick,
  actionText,
  subText,
  disabled,
}) => (
  <div className="settings-content-block">
    <div>
      {label}
      {subText}
    </div>
    <div>
      <TertiaryButton
        className="btn"
        disabled={disabled}
        onClick={() => onClick()}
      >
        {actionText}
      </TertiaryButton>
    </div>
  </div>
);

export default InlineLabelButton;
