import TertiaryButton from '@/components/library/buttons/tertiary-button';
import { useDropzone } from 'react-dropzone';

const ImageField = ({ label, small, fileName, onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
    onDrop: onDrop,
  });

  return (
    <TertiaryButton className="btn">
      <div {...getRootProps({ className: 'drop-field-button' })}>
        <input {...getInputProps()} />
        <p>Select Photo</p>
      </div>
    </TertiaryButton>
  );
};

export default ImageField;
