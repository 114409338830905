import bardAPI from '@/bard-api';
import AudioPlayer from '@/components/audio-player/index';
import PostLayout from '@/components/post-layout';
import { AUDIO_REPORT, uRequestHandler } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Audio = () => {
  const [audio, setAudio] = useState({});
  const [collabs, setCollabs] = useState([]);
  const [collections, setCollections] = useState([]);
  const { id: audioId } = useParams();

  const getInitialData = useCallback(async () => {
    try {
      const audio = await bardAPI.get(`audios/${audioId}`);
      const collabs = await bardAPI.get(`collabs/?audio_id=${audioId}`);
      const collections = await bardAPI.get(`collections/?audio=${audioId}`);
      setAudio(audio.data);
      setCollabs(collabs.data);
      setCollections(collections.data);
    } catch (error) {
      uRequestHandler(error);
    }
  }, [audioId]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const savePost = async (genericData) => {
    try {
      const newAudio = await bardAPI.patch(`audios/${audioId}/`, genericData);

      setAudio(newAudio.data);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  return (
    <PostLayout
      post={audio}
      collabs={collabs}
      collections={collections}
      reportCategory={AUDIO_REPORT}
      linkRoot={'audio'}
      content={<AudioPlayer id={`collab-audio-${1}`} audio={audio} />}
      savePost={savePost}
    />
  );
};

export default Audio;
