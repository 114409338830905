import bardAPI from '@/bard-api';
import IconLink from '@/components/library/icon-link';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { Twitter } from 'react-feather';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.card.background};
  border-top: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  padding: 36px 80px;
  position: absolute;
  bottom: 0px;
  margin-top: 24px;
  width: 100%;
  .footer-body {
    max-width: 1280px;
    margin: auto;
    .footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .footer-left {
        display: flex;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;
        a.icon {
          height: 24px;
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .footer-right {
        color: ${({ theme }) => theme.colors.bodyText};
      }
    }
  }

  @media (max-width: 700px) {
    padding: 36px 20px;

    .footer-content {
      flex-direction: column;
      gap: 18px;
    }
  }
`;

const Footer = () => {
  const [stats, setStats] = useState({});

  const getStats = async () => {
    try {
      const result = await bardAPI.get(`stats/`);
      setStats(result.data);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <FooterWrapper>
      <div className="footer-body">
        <div className="footer-content">
          <div className="footer-left">
            <a
              href="https://site.bard.art/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website
            </a>
            <a
              href="https://www.termsfeed.com/privacy-policy/a73aca3759d6d3cd07465dfc7b54cdfe"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
            <a
              href="https://www.termsfeed.com/terms-conditions/97c00a1dabd1b42b067919c18f66ef41"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
            <IconLink website={'joeatbard'} icon={<Twitter />} type="twitter" />
          </div>

          <div className="footer-right">
            <div className="footer-collab-count">
              <span>{stats.collab_count} </span>
              Collabs
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
