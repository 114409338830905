import { useSelector } from 'react-redux';
import AddLayout from './add-layout';

const AddAudio = ({
  collab,
  inModal,
  handleCloseModal,
  getCollabData,
  forceUploadNew,
  resetPage,
}) => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <AddLayout
      inModal={inModal}
      type="audios"
      collab={collab}
      handleCloseModal={handleCloseModal}
      getCollabData={getCollabData}
      resetPage={resetPage}
      forceUploadNew={!currentUser.audio_user || forceUploadNew}
    />
  );
};

export default AddAudio;
