import { PanelMask } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import SecondaryButton, {
  SecondaryDropdown,
} from '@/components/library/buttons/secondary-button';
import TextButton from '@/components/library/buttons/text-button';
import { DropdownItem, DropdownMenu } from '@/components/library/dropdown';
import NotSignedIn from '@/components/modals/not-signed-in';
import TagPanel from '@/containers/landing/filter-bar/tag-panel.js';
import Tag from '@/containers/landing/filter-bar/tag.js';
import Wrapper from '@/containers/landing/filter-bar/wrapper.js';
import { CONTENT, MAKERS } from '@/store/landing';
import { useState } from 'react';
import { Filter, Search, Upload, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Filters = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: 400px) {
    width: 100%;

    > div {
      flex: 1;
    }
  }
`;

const CollectionFilterBar = ({
  collectionId,
  setTags,
  setMaker,
  setContentType,
  tags,
  maker,
  contentType,
}) => {
  const [showTagPanel, setShowTagPanel] = useState(false);
  const [search, setSearch] = useState('');
  const [showNotSignedIn, setShowNotSignedIn] = useState(false);

  const currentUser = useSelector((state) => state.currentUser);
  const isLoggedIn = currentUser.id;
  const navigate = useNavigate();

  const renderTags = () => {
    return tags.map((tag, index) => {
      return (
        <Tag key={index}>
          #{tag}
          <X
            role="link"
            tabIndex={0}
            onClick={() => removeTag(tag)}
            onKeyPress={() => removeTag(tag)}
          />
        </Tag>
      );
    });
  };

  const searchTags = (event) => {
    if (
      (event.key === 'Enter' || event.key === ' ') &&
      !tags.includes(search)
    ) {
      setTags([...tags, search]);
      setSearch('');
    }
  };

  const removeTag = (tag) => {
    const tagsCopy = [...tags];
    const index = tagsCopy.indexOf(tag);
    if (index > -1) {
      tagsCopy.splice(index, 1);
      setTags(tagsCopy);
    }
  };

  const goToNewCollab = () => {
    if (isLoggedIn) {
      navigate(`/new/${collectionId}/`);
    } else {
      setShowNotSignedIn(true);
    }
  };

  return (
    <Wrapper>
      <Filters>
        {isLoggedIn && (
          <SecondaryDropdown
            text={MAKERS[maker]}
            icon="chevron down"
            iconposition="right"
            style={{
              minWidth: '128px',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <DropdownMenu>
              <DropdownMenu scrolling>
                {Object.keys(MAKERS).map((key) => (
                  <DropdownItem
                    key={key}
                    onClick={() => setMaker(key)}
                    text={MAKERS[key]}
                  />
                ))}
              </DropdownMenu>
            </DropdownMenu>
          </SecondaryDropdown>
        )}
        <SecondaryDropdown
          text={CONTENT[contentType]}
          icon="chevron down"
          iconposition="right"
          style={{
            minWidth: '128px',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <DropdownMenu>
            <DropdownMenu scrolling>
              {Object.keys(CONTENT).map((key) => (
                <DropdownItem
                  key={key}
                  onClick={() => setContentType(key)}
                  text={CONTENT[key]}
                />
              ))}
            </DropdownMenu>
          </DropdownMenu>
        </SecondaryDropdown>
      </Filters>
      <div className="collab-filters">
        {tags.length > 0 && (
          <div className="filter-tag-count">{tags.length}</div>
        )}
        <SecondaryButton
          className="btn"
          onClick={() => setShowTagPanel(!showTagPanel)}
        >
          <Filter />
          Filter
        </SecondaryButton>
        {isLoggedIn && (
          <PrimaryButton className="btn" onClick={() => goToNewCollab()}>
            <Upload />
            Add to Collection
          </PrimaryButton>
        )}
        {showTagPanel && (
          <TagPanel>
            <div className="filter-panel-content">
              <div className="filter-search-bar">
                <Search />
                <input
                  className="small-input filter-collabs-input"
                  name="tag"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={searchTags}
                  placeholder="Search and add tags"
                />
              </div>
              {tags.length > 0 && (
                <div className="filter-search-bar">
                  {renderTags()}
                  <TextButton onClick={() => changeTags([])}>Clear</TextButton>
                </div>
              )}
            </div>
          </TagPanel>
        )}
      </div>
      {showTagPanel && <PanelMask onClick={() => setShowTagPanel(false)} />}
      <NotSignedIn
        showModal={showNotSignedIn}
        setModalVisibility={(visible) => setShowNotSignedIn(!visible)}
      />
    </Wrapper>
  );
};

export default CollectionFilterBar;
