import BeatLoader from 'react-spinners/BeatLoader';
import styled, { useTheme } from 'styled-components';

export const EmptyState = styled.p`
  text-align: center;
  margin: auto;
  padding: ${({ $small }) => ($small ? '20px 0' : '40px 0')};
  max-width: 400px;
  font-size: ${({ $small }) => ($small ? '18px' : '20px')};
`;

const LoaderWrapper = styled.div`
  margin: 80px auto 80px auto;
  width: 0px;
`;

const LoadingEmptyState = ({
  noResults,
  noData,
  isLoading,
  small,
  data,
  search,
}) => {
  const theme = useTheme();

  if (search && !isLoading && data.length === 0) {
    return <EmptyState $small={small}>{noResults}</EmptyState>;
  } else if (!isLoading && data.length === 0) {
    return <EmptyState $small={small}>{noData}</EmptyState>;
  } else if (isLoading) {
    return (
      <LoaderWrapper>
        <BeatLoader
          sizeunit="px"
          size={24}
          color={theme.colors.highlight}
          loading={true}
        />
      </LoaderWrapper>
    );
  }

  return <></>;
};

export default LoadingEmptyState;
