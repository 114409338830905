import LoadingEmptyState from '@/components/library/loading-empty-state';
import AudioPost from '@/components/pieces/audio-post';
import VisualPost from '@/components/pieces/visual-post';
import WritingPost from '@/components/pieces/writing-post';
import { XBlock, XMasonry } from 'react-xmasonry';
import styled from 'styled-components';

const PostingWrapper = styled.div`
  border: ${({ theme, isSelected, selectable }) =>
    selectable
      ? isSelected
        ? `2px solid ${theme.colors.highlight}`
        : `2px solid ${theme.colors.card.background}`
      : 'none'};
  margin: 8px;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
  }

  .visual-container,
  .audio-container,
  .writing-container {
    padding: 18px;
    background-color: ${({ theme }) => theme.colors.card.background};
    border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
    border-radius: 6px;
  }
`;

const PostingsList = ({
  data,
  endpoint,
  selectable,
  setSelected,
  selected,
  singleSelection,
}) => {
  const renderPosts = () => {
    return data.map((post) => {
      let isSelected = false;
      let isMultiSelect = false;
      if (selectable) {
        isSelected = singleSelection
          ? selected === post.id
          : selected.includes(post.id);
        isMultiSelect = !singleSelection && selectable;
      }

      return (
        <XBlock key={post.id}>
          <PostingWrapper isSelected={isSelected} selectable={selectable}>
            {endpoint === 'visuals' && (
              <VisualPost
                selectable={selectable}
                setSelected={() => setSelected(post.id)}
                post={post}
                isMultiSelect={isMultiSelect}
                selectedList={selected}
              />
            )}
            {endpoint === 'writings' && (
              <WritingPost
                selectable={selectable}
                setSelected={() => setSelected(post.id)}
                post={post}
              />
            )}
            {endpoint === 'audios' && (
              <AudioPost
                selectable={selectable}
                setSelected={() => setSelected(post.id)}
                post={post}
              />
            )}
          </PostingWrapper>
        </XBlock>
      );
    });
  };

  return (
    <div id={`posting-list-${endpoint}`}>
      <XMasonry maxColumns={3} targetBlockWidth={400}>
        {renderPosts()}
      </XMasonry>
      <LoadingEmptyState
        noData="This user has no posts of this type"
        data={data}
      />
    </div>
  );
};

export default PostingsList;
