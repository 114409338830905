import bardAPI from '@/bard-api';
import CollabsList from '@/components/collabs-list';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import PageModalHeader from '@/components/library/page-modal-header';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const SavedCollabs = () => {
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [offset, setOffset] = useState(0);
  const [collabs, setCollabs] = useState([]);
  const currentUser = useSelector((state) => state.currentUser);

  const getData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await bardAPI.get(
        `collabs/?` + `saved=${currentUser.id}&offset=${offset * 10}&limit=10`,
      );
      setLoading(false);
      setTotalData(response.data.count);
      setCollabs((collabs) => collabs.concat(response.data.results));
    } catch (error) {
      setLoading(false);
    }
  }, [currentUser, offset]);

  useEffect(() => {
    getData();
  }, [getData]);

  const trackScrolling = useCallback(() => {
    const wrappedElement = document.getElementById('collabs-list-container');
    if (
      wrappedElement.getBoundingClientRect().bottom <=
        window.innerHeight + 700 &&
      !loading &&
      collabs.length < totalData
    ) {
      setOffset((offset) => offset + 1);
    }
  }, [loading, collabs, totalData]);

  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return document.removeEventListener('scroll', trackScrolling);
  }, [trackScrolling]);

  return (
    <PageContainer id="collabs-list-container">
      <ContentContainer>
        <PageModalHeader
          location={'/'}
          title="Inspiration / Saved Collaborations"
        />

        <CollabsList collabs={collabs} loading={loading} />
      </ContentContainer>
      <script>feather.replace()</script>
    </PageContainer>
  );
};

export default SavedCollabs;
