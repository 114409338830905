import styled from 'styled-components';

export const PaymentProgressTitles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  font-family: 'Montserrat';
  margin-bottom: 12px;
  margin-top: 18px;
  div {
    padding: 0 6px;
  }
`;

const PaymentProgressBar = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: '100%';
  height: 20px;
  margin-bottom: 4px;
  > div {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div:first-of-type {
      background-color: ${({ theme }) => theme.colors.highlight};
      height: 20px;
    }
    > div:last-of-type {
      background-color: ${({ theme }) => theme.colors.card.background};
      height: 20px;
    }
    &:first-of-type {
      border-radius: 8px 0 0 8px;
      > div:first-of-type {
        border-radius: 8px 0 0 8px;
      }
    }
    &:last-of-type {
      border-radius: 0 8px 8px 0;
      > div:last-of-type {
        border-radius: 0 8px 8px 0;
      }
    }
  }
`;

export default PaymentProgressBar;
