import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

const SecondaryButton = styled.button`
  color: ${({ theme }) => theme.colors.buttons.secondary.color};
  background-color: ${({ theme }) => theme.colors.buttons.secondary.background};
  border: 1px solid ${({ theme }) => theme.colors.buttons.secondary.border};
  padding: ${({ $round }) => ($round ? '0.6rem' : '0.6rem 1.2rem')};
  border-radius: ${({ $round }) => ($round ? '50px' : '4px')};
  height: ${({ $round }) => ($round ? '38px' : 'auto')};
  width: ${({ $round }) => ($round ? '38px' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.buttons.secondary.icon};
    margin-right: ${({ $round }) => ($round ? '0px' : '0.7rem')};
    height: 16px;
    width: 16px;
    flex: 1 0 16px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.buttons.secondary.background};
    background-color: ${({ theme }) => theme.colors.buttons.secondary.color};
    border: solid 2px
      ${({ theme }) => theme.colors.buttons.secondary.background};

    svg {
      color: ${({ theme }) => theme.colors.buttons.secondary.background};
    }
  }

  &.btn:focus,
  &.btn:active {
    border: solid 2px ${({ theme }) => theme.colors.buttons.secondary.border};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.buttons.secondary.disabled};
    border: solid 2px ${({ theme }) => theme.colors.buttons.secondary.disabled};
    color: #dcdcdc;
  }
`;

export const SecondaryDropdown = styled(Dropdown)`
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.buttons.secondary.color};
  background-color: ${({ theme }) => theme.colors.buttons.secondary.background};
  border: 1px solid ${({ theme }) => theme.colors.buttons.secondary.background};
  padding: 0.6rem 1.2rem;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 18px;

  div {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
  }

  i.icon {
    color: ${({ theme }) => theme.colors.buttons.secondary.icon};
    height: 16px;
    margin-right: 0px;
    margin-left: 0.4rem;
  }

  svg {
    color: ${({ theme }) => theme.colors.buttons.secondary.icon};
    margin-right: 0.7rem;
    height: 16px;
    width: 16px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.buttons.secondary.background};
    background-color: ${({ theme }) => theme.colors.buttons.secondary.color};
    border: solid 2px
      ${({ theme }) => theme.colors.buttons.secondary.background};

    svg,
    i.icon {
      color: ${({ theme }) => theme.colors.buttons.secondary.background};
    }
  }

  &.btn:focus,
  &.btn:active {
    border: solid 2px
      ${({ theme }) => theme.colors.buttons.secondary.background};
  }
`;

export default SecondaryButton;
