import styled from 'styled-components';

export const NavbarWrapperWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.body};
`;

const NavbarWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 20px 60px;
  max-width: 1280px;
  margin: auto;
  gap: 18px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.body};

  @media (max-width: 700px) {
    padding: 20px 20px;
  }
`;

export default NavbarWrapper;
