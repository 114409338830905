import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavbarNavOption = styled(Link)`
  color: ${({ theme }) => theme.colors.bodyText};
  display: flex;
  gap: 2px;
  align-items: center;
  font-weight: ${({ $active }) => ($active ? '600' : '500')};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.bodyText};
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

export default NavbarNavOption;
