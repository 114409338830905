import { FieldHighlight } from '@/components/library/typography/text';
import { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { FlexRowGap } from '../basic-styled-components';
import TertiaryButton from '../buttons/tertiary-button';

const Wrapper = styled.div`
  padding: ${({ $inputEnabled }) =>
    $inputEnabled ? '17px 0 18px 0' : '20px 0'};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .ui.form {
    display: flex;
    flex-grow: 1;

    .field {
      width: 90%;
      max-width: 600px;
    }
  }
`;

const InlineInput = ({
  placeholder,
  onSubmit,
  defaultValue,
  checkForErrors = () => '',
  important,
  actionText,
  textArea,
}) => {
  const [enableInput, setEnableInput] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const saveValue = async () => {
    const error = await checkForErrors(value);
    if (error) {
      setError(error);
    } else {
      setError('');
      onSubmit(value);
      setEnableInput(false);
    }
  };

  return (
    <Wrapper $inputEnabled={enableInput}>
      {enableInput ? (
        <>
          <Form>
            <Form.Field>
              {textArea ? (
                <textarea
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              ) : (
                <input
                  className={important ? 'important-visible-input' : ''}
                  placeholder={placeholder}
                  name={placeholder}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              )}
            </Form.Field>
          </Form>
          <FlexRowGap $gap="8px">
            <TertiaryButton className="btn" onClick={saveValue}>
              Save
            </TertiaryButton>
            <TertiaryButton
              className="btn"
              onClick={() => setEnableInput(false)}
            >
              Cancel
            </TertiaryButton>
          </FlexRowGap>
          {error && <FieldHighlight>{error}</FieldHighlight>}
        </>
      ) : (
        <>
          <div style={{ height: textArea ? '72px' : 'auto' }}>
            {value || placeholder}
          </div>
          <TertiaryButton
            className="btn"
            onClick={() => {
              setEnableInput(true);
              setError('');
            }}
          >
            {actionText}
          </TertiaryButton>
        </>
      )}
    </Wrapper>
  );
};

export default InlineInput;
