import { SelectedDot } from '@/components/library/basic-styled-components';
import S3Visual from '@/components/library/s3-visual';
import { Link } from 'react-router-dom';

const VisualPost = ({
  selectable,
  setSelected,
  post,
  isMultiSelect,
  selectedList,
}) => {
  return (
    <div className="visual-container">
      {isMultiSelect && selectedList.indexOf(post.id) > -1 && (
        <SelectedDot>{selectedList.indexOf(post.id) + 1}</SelectedDot>
      )}
      {selectable ? (
        <div
          className="selectable-content"
          onClick={() => setSelected(post.id)}
        >
          <S3Visual path={post.file_path} />
        </div>
      ) : (
        <Link to={`/visual/${post.id}`}>
          <S3Visual path={post.file_path} />
        </Link>
      )}
    </div>
  );
};

export default VisualPost;
