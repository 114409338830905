import styled from 'styled-components';

const Wrapper = styled.div`
  width: 390px;
  display: block;
  position: absolute;
  z-index: 200;
  right: 40px;
  top: 40px;
  max-height: 600px;
  background-color: ${({ theme }) => theme.colors.card.background};
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  border-radius: 6px;
  .notification-panel-header {
    .notification-panel-options {
      display: flex;
      padding: 6px 8px 0px 8px;
      justify-content: space-between;
      align-items: center;
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
    }
    .notification-panel-title {
      padding: 6px;
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
    }
  }
  .notification-panel-body {
    padding: 14px 14px 0 14px;
    overflow-y: scroll;
    max-height: 480px;
    background: ${({ theme }) => theme.colors.card.background};
  }
  .notification-panel-emptystate {
    text-align: center;
    color: ${({ theme }) => theme.colors.subtext};
    padding: 70px 0 84px 0;
  }
  .notification-panel-footer {
    padding: 6px 0px;
    text-align: center;
    border-top: ${({ theme }) => `1px solid ${theme.colors.border}`};
    border-radius: 0 0 4px 4px;
  }

  @media (max-width: 620px) {
    width: 94vw;
    right: 0;
  }
`;

export default Wrapper;
