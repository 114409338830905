import bardAPI from '@/bard-api';
import { FlexRowGap } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import { fetchUserAfterUpdate } from '@/store/user';
import { uRequestHandler } from '@/utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SubScribeEmail = ({ user }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const [msg, setMsg] = useState('');

  const subscribe = async () => {
    try {
      await bardAPI.post(`email-subscription/`);
      dispatch(fetchUserAfterUpdate());
      setMsg('Successfully subscribed');
    } catch (e) {
      uRequestHandler(e);
    }
  };

  const unSubscribe = async () => {
    try {
      await bardAPI.delete(`email-subscription/`);
      dispatch(fetchUserAfterUpdate());
      setMsg('Successfully unsubscribed');
    } catch (e) {
      uRequestHandler(e);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '18px',
      }}
    >
      <div className="settings-section-description">
        We have one email list for updates, events, features, etc. We do our
        best to be conscious about how annoying email spam is so you can expect
        regular but not frequent emails from us.
      </div>
      <FlexRowGap $gap="8px">
        <p>{msg}</p>
        {currentUser.subscribed_newsletter ? (
          <SecondaryButton className="btn" onClick={unSubscribe}>
            Unsubscribe
          </SecondaryButton>
        ) : (
          <PrimaryButton className="btn" onClick={subscribe}>
            Subscribe
          </PrimaryButton>
        )}
      </FlexRowGap>
    </div>
  );
};

export default SubScribeEmail;
