import { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { FlexRowGap } from '../basic-styled-components';

const InlineCheckbox = ({ label, onSubmit, defaultValue }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  const check = () => {
    const newValue = !checked;
    setChecked(newValue);
    onSubmit(newValue);
  };

  return (
    <div className="settings-content-block">
      <FlexRowGap $gap="4px">
        <Checkbox label="" checked={checked} onChange={check} />
        <p>{label}</p>
      </FlexRowGap>
    </div>
  );
};

export default InlineCheckbox;
