import styled from 'styled-components';

export const Collaboration = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 12px 18px;
  background-color: ${({ theme }) => theme.colors.card.background};
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  border-radius: 8px;
  width: 96%;
  margin: auto;
  margin-bottom: 20px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .visual-container,
  .writing-container,
  .audio-container {
    padding: 8px 0;
    img {
      width: 100%;
    }
  }
  .audio-container {
    display: flex;
    overflow: hidden;
    a {
      max-width: 100%;
    }
  }
`;
