import styled from 'styled-components';

const AddToCollabWrapper = styled.div`
  padding: ${({ $modal }) => ($modal ? '0' : '20px')};
  background-color: ${({ theme }) => theme.colors.card.background};
  margin-bottom: ${({ $modal }) => ($modal ? '0px' : '36px')};
  .add-to-collab-footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footer-actions {
      display: flex;
      flex-direction: row;
      gap: 18px;
    }
  }
  .add-to-collab-loading {
    margin: 73px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @media only screen and (max-width: 540px) {
    padding: ${({ $modal }) => ($modal ? '0px' : '20px')};
  }
`;

export default AddToCollabWrapper;
