import UserProvider from '@/components/user-provider.jsx';
import ThemeManager from '@/containers/theme-provider.jsx';
import store from '@/store/index';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { unregister } from './registerServiceWorker';
import Router from './router';
import './semantic/dist/semantic.min.css';
import { GlobalStyles } from './themes/global';

const history = createBrowserHistory();

const root = createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain={import.meta.env.VITE_APP_AUTH_DOMAIN}
    clientId={import.meta.env.VITE_APP_AUTH_CLIENT}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: import.meta.env.VITE_APP_AUTH_AUD,
      scope: 'read:current_user read:email email offline_access',
    }}
  >
    <Provider store={store}>
      <BrowserRouter history={history}>
        <ThemeManager>
          <GlobalStyles />
          <UserProvider>
            <Router />
          </UserProvider>
        </ThemeManager>
      </BrowserRouter>
    </Provider>
  </Auth0Provider>,
);

unregister();
