import { FlexRowGap } from '@/components/library/basic-styled-components';
import SecondaryButton from '@/components/library/buttons/secondary-button';
import CollabActionBar from '@/components/library/collab-action-bar';
import IconToggle from '@/components/library/icon-toggle';
import UserImg from '@/components/user-img';
import { likeCollab, saveCollab } from '@/store/landing';
import { Bookmark, Heart, MessageCircle, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const CollabHeaderWrapper = styled.div`
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  width: ${({ $sticky }) => ($sticky ? '112%' : '112%')};
  position: ${({ $sticky }) => ($sticky ? 'fixed' : 'relative')};
  top: ${({ $sticky }) => ($sticky ? '0px' : 'auto')};
  background-color: ${({ $sticky, theme }) =>
    $sticky ? theme.colors.card.background : 'auto'};
  max-width: ${({ $sticky }) => ($sticky ? '800px' : 'auto')};
  padding: ${({ $sticky }) => ($sticky ? '30px 20px' : 'auto')};
  margin: ${({ $sticky }) => ($sticky ? '0 0 0 -50px' : '0 0 80px -6%')};
  border-radius: 0 0 8px 8px;
  border: ${({ $sticky, theme }) =>
    $sticky ? `1px solid ${theme.colors.card.border}` : 'none'};

  @media (max-width: 820px) {
    width: ${({ $sticky }) => ($sticky ? '96%' : '100%')};
    left: ${({ $sticky }) => ($sticky ? '2%' : 'auto')};
    margin: ${({ $sticky }) => ($sticky ? '0 0 0 0' : '0 0 80px 0')};
  }
`;

const CollabHeader = ({ collab, scrollToComments, liked, saved, sticky }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);

  const isLoggedIn = currentUser.id;
  return (
    <CollabHeaderWrapper $sticky={sticky}>
      <FlexRowGap $gap="10px">
        {collab.visual && <UserImg user={collab.visual.user} />}
        {collab.audio && <UserImg user={collab.audio.user} />}
        {collab.writing && <UserImg user={collab.writing.user} />}
      </FlexRowGap>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {isLoggedIn ? (
          <CollabActionBar $gap={'16px'}>
            <IconToggle
              toggled={liked}
              title="Like collab"
              toggle={() => dispatch(likeCollab(collab.id))}
              icon={<Heart />}
              fillClass="fill-heart"
            />
            <IconToggle
              toggled={saved}
              title="Save collab"
              toggle={() => dispatch(saveCollab(collab.id))}
              icon={<Bookmark />}
              fillClass="fill-bookmark"
            />
            <div
              role="link"
              tabIndex={0}
              title="Go to comments"
              onClick={scrollToComments}
              onKeyPress={scrollToComments}
            >
              <MessageCircle />
            </div>
            <SecondaryButton
              className="btn"
              onClick={() => navigate('/')}
              $round
            >
              <X />
            </SecondaryButton>
          </CollabActionBar>
        ) : (
          <SecondaryButton className="btn" onClick={() => navigate('/')} $round>
            <X />
          </SecondaryButton>
        )}
      </div>
    </CollabHeaderWrapper>
  );
};

export default CollabHeader;
