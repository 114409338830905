import UserHeader from '@/components/user-header';
import { WRITING_REPORT } from '@/utils';
import styled from 'styled-components';
import { AboutText, CollabBlockHeader, PostedDate } from './components';

const Wrapper = styled.div`
  .collab-writing-text {
    background-color: ${({ theme }) => theme.colors.card.background};
    padding: 20px;
    border-radius: 6px;
    word-break: break-word;
  }
`;

const Writing = ({ writing, refetchData, collabId }) => {
  const { name, user, about, writing: content } = writing;

  const renderWriting = () => (
    <span
      dangerouslySetInnerHTML={{ __html: `${content}` }}
      style={{ fontSize: '17px', lineHeight: '26px' }}
    />
  );

  return (
    <Wrapper>
      {name && <CollabBlockHeader>{name}</CollabBlockHeader>}
      <UserHeader
        user={user}
        reportObject={writing}
        reportCategory={WRITING_REPORT}
        removeData={() => refetchData(collabId)}
      />
      {about && <AboutText>{about}</AboutText>}
      <div className="collab-writing-text">{renderWriting()}</div>
      <PostedDate>
        {new Date(writing.created_date).toLocaleDateString()}
      </PostedDate>
    </Wrapper>
  );
};

export default Writing;
