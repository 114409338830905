import styled from 'styled-components';

const NotificationPageHeader = styled.div`
  .notification-page-header {
    .notification-page-options {
      display: flex;
      flex-wrap: wrap;
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
      .notification-page-option {
        height: 36px;
        color: ${({ theme }) => theme.colors.subtext};
        text-align: center;
        padding: 8px 14px 4px 14px;
        &.selected {
          border-bottom: ${({ theme }) =>
            `2px solid ${theme.colors.highlight}`};
        }
        svg {
          height: 20px;
          width: 20px;
          margin-top: 4px;
        }
        &:hover {
          cursor: pointer;
        }
        &:last-of-type {
          border-right: none;
        }
      }
    }
  }
  .notification-page-body {
    padding: 8px 0;
  }
`;

export default NotificationPageHeader;
