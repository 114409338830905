import Footer from '@/components/footer';
import PrimaryButton from '@/components/library/buttons/primary-button';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import Navbar from '@/components/navbar/index';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <PageContainer>
      <Navbar />
      <ContentContainer $type="form">
        <img
          style={{ width: '80px', padding: '8px 0 6px 0' }}
          src={'/media/icon.png'}
          alt="logo"
        />
        <h1>Not Found!</h1>
        <p style={{ marginBottom: '60px', fontSize: '18px' }}>
          Oh no! It looks like we have led you astray, click the button below to
          go back to the home page :)
        </p>
        <Link to="/">
          <PrimaryButton className="btn">Home Page</PrimaryButton>
        </Link>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};

export default NotFound;
