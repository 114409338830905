import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .collab-swap-icons {
    position: absolute;
    right: 0px;
    top: 32px;
    margin-bottom: 16px;
  }
`;

export default Wrapper;
