import TertiaryButton from '@/components/library/buttons/tertiary-button';
import { Collaboration } from '@/components/library/collaborations/collaboration';
import { Link, useNavigate } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { XBlock, XMasonry } from 'react-xmasonry';
import styled, { useTheme } from 'styled-components';
import AudioPiece from './pieces/audio-piece';
import VisualPiece from './pieces/visual-piece';
import WritingPiece from './pieces/writing-piece';

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0;

  p {
    margin-bottom: 12px;
  }
`;

const CollabsList = ({ collabs, loading, hiddenPiece }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const hideVisual = hiddenPiece === 'visual';
  const hideAudio = hiddenPiece === 'audio';
  const hideWriting = hiddenPiece === 'writing';
  const filteredCollabs = collabs.filter((collab) => {
    const hideWholePost =
      (hideVisual && !collab.writing && !collab.audio) ||
      (hideAudio && !collab.writing && !collab.visual) ||
      (hideWriting && !collab.audio && !collab.visual);

    return !hideWholePost;
  });
  const showLinkToView = filteredCollabs.length === 0 && collabs.length === 1;
  return (
    <div style={{ width: '102%', marginLeft: '-1%' }}>
      {filteredCollabs.length > 0 ? (
        <XMasonry maxColumns={3} targetBlockWidth={400} center={false}>
          {collabs.map((collab, index) => (
            <XBlock key={collab.id}>
              <Collaboration>
                {collab.visual && !hideVisual && (
                  <Link to={`/${collab.id}`} style={{ width: '100%' }}>
                    <VisualPiece visual={collab.visual} />
                  </Link>
                )}
                {collab.writing && !hideWriting && (
                  <Link to={`/${collab.id}`}>
                    <WritingPiece writing={collab.writing} />
                  </Link>
                )}
                {collab.audio && collab.audio.id && !hideAudio && (
                  <AudioPiece audio={collab.audio} collab={collab} />
                )}
              </Collaboration>
            </XBlock>
          ))}
        </XMasonry>
      ) : (
        <EmptyWrapper>
          {!loading && <p>We didn't find any collaborations</p>}
          {loading && (
            <BeatLoader
              sizeunit="px"
              size={24}
              color={theme.colors.highlight}
              loading={true}
            />
          )}
          {showLinkToView && (
            <TertiaryButton
              className="btn"
              onClick={() => navigate(`/${collabs[0].id}`)}
            >
              Collaborate with it here
            </TertiaryButton>
          )}
        </EmptyWrapper>
      )}
    </div>
  );
};

export default CollabsList;
