import bardAPI from '@/bard-api';
import Footer from '@/components/footer';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import PageIntro from '@/components/library/page-intro';
import { ItemHeader } from '@/components/library/typography/header';
import Navbar from '@/components/navbar/index';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Badges = () => {
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    getBadges();
  }, []);

  const getBadges = async () => {
    try {
      const response = await bardAPI.get(`badges/`);
      setBadges(response.data);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  return (
    <PageContainer>
      <Navbar />
      <ContentContainer>
        <PageIntro
          title="Badges"
          intro="
            Bard commissions artists to create badges for a variety of
            achievements you can get while using Bard. It gives artists the
            opportunity to leave their mark on the platform forever. Whenever we
            need a new badge we randomly select an artist from a list to do it.
            If you would like to be added to that list, shoot us a message!
          "
        />

        <div
          style={{ maxWidth: '540px', margin: 'auto', marginBottom: 120 }}
          className="badges-list"
        >
          {badges.map((badge) => (
            <div
              key={badge.id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '48px',
                gap: '24px',
              }}
            >
              <img
                src={badge.file_path}
                alt={badge.name}
                style={{ width: 160, height: 160 }}
              />
              <div>
                <ItemHeader>{badge.name}</ItemHeader>
                {badge.creator_name ? (
                  <Link to={`/profile/${badge.creator_name}`}>
                    <ItemHeader>Created By: {badge.creator_name}</ItemHeader>
                  </Link>
                ) : (
                  <></>
                )}

                <div className="basic-text" style={{ marginTop: '8px' }}>
                  {badge.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};

export default Badges;
