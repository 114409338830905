import AddWriting from '@/components/add-writing';
import AddPieceSlot from '@/containers/collaboration/add-piece-slot';
import Writing from '@/containers/collaboration/writing';
import { Edit2 } from 'react-feather';

const WritingBlock = ({ collab, getCollabData, editing, showEdit }) => {
  const { writing, id: collabId } = collab;

  const canAddWriting = !writing;
  return (
    <>
      {writing && (
        <Writing
          writing={writing}
          refetchData={getCollabData}
          collabId={collabId}
        />
      )}
      {canAddWriting && (
        <>
          {editing ? (
            <AddWriting
              getCollabData={getCollabData}
              collab={collab}
              resetPage={() => showEdit(null)}
            />
          ) : (
            <AddPieceSlot
              showEdit={() => showEdit(2)}
              text="Add a Story or Poem"
              icon={<Edit2 />}
            />
          )}
        </>
      )}
    </>
  );
};

export default WritingBlock;
