import styled from 'styled-components';

const CollabActionBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 56px;
  gap: ${({ $gap }) => ($gap ? $gap : 0)};
  .fill-heart svg {
    color: ${({ theme }) => theme.colors.love};
    fill: ${({ theme }) => theme.colors.love};
  }
  .fill-bookmark svg {
    color: ${({ theme }) => theme.colors.subtext};
    fill: ${({ theme }) => theme.colors.subtext};
  }
  div svg {
    color: ${({ theme }) => theme.colors.subtext};
    width: 24px;
    height: 24px;
  }

  svg:hover {
    cursor: pointer;
  }
`;

export default CollabActionBar;
