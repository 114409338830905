import styled from 'styled-components';

const Banner = styled.div`
  width: 100vw;
  padding: 40px 0;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.secondaryHighlight};
  color: ${({ theme }) => theme.colors.invertText};

  .shell-container {
    max-width: 1280px;
    padding: 0px 60px;
    margin: auto;
  }
  .banner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .banner-text {
    max-width: 700px;
    .banner-title {
      margin-bottom: 12px;
    }
    .banner-secondary {
      color: ${({ theme }) => theme.colors.invertText};
      margin-bottom: 18px;
    }
  }
  .banner-image {
    max-width: 300px;
  }

  @media (max-width: 450px) {
    .shell-container {
      padding: 20px;
    }
  }
`;

export default Banner;
