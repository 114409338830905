import bardAPI from '@/bard-api';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import UserImgDefault from './user-img-default';

const DropdownWrapper = styled.div`
  position: absolute;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.card.background};
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  width: 220px;
  z-index: 2;
  top: 48px;
  left: 4px;

  .input-dropdown-option {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
    padding: 3px 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    overflow: hidden;

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.highlight};
    }

    img {
      width: 24px;
      max-width: 24px;
      object-fit: contain;
    }
  }
`;

const SearchDropdown = ({
  handleChange,
  placeholder,
  customClass,
  defaultComment,
}) => {
  const [users, setUsers] = useState([]);
  const [comment, setComment] = useState('');
  const [searching, setSearching] = useState(false);
  const searchDropdown = useRef();

  useEffect(() => {
    setComment(defaultComment);
  }, [defaultComment]);

  const queryUsers = async (username) => {
    if (!searching) {
      setSearching(true);
      const response = await bardAPI.get(
        `users/?search=${username}&offset=0&limit=4&ordering=-username`,
      );
      setUsers(response.data.results);
      setSearching(false);
      return response.data.results;
    }
    return [];
  };

  const selectFirstUser = async (event) => {
    const value = event.target.value;

    const cursorLocation = searchDropdown.current.selectionStart;
    const commentSegment = value.substr(0, cursorLocation);
    let lastIndex = commentSegment.lastIndexOf(' ');
    if (lastIndex === -1) {
      lastIndex = 0;
    } else {
      lastIndex += 1;
    }
    const theWord = commentSegment.substring(lastIndex, commentSegment.length);

    if (event.key === 'Enter' && theWord.includes('@')) {
      const users = await queryUsers(theWord.substr(1));
      if (users.length > 0) {
        selectUser(users[0].username);
        setUsers([]);
      }
    }
  };

  const selectUser = (username) => {
    const cursorLocation = searchDropdown.current.selectionStart;

    // Segment of string we are modifying
    const commentSegment = comment.substr(0, cursorLocation);
    // Location of the word we are typing out
    const lastIndex = commentSegment.lastIndexOf(' ');
    const commentStart = comment.substring(0, lastIndex);
    const commentEnd = comment.substring(cursorLocation, comment.length);

    let newComment = `${commentStart} @${username} ${commentEnd}`;
    if (commentStart.length === 0) {
      newComment = `@${username} ${commentEnd}`;
    }

    setUsers([]);
    setComment(newComment);
    handleChange(newComment);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    setComment(value);
    setUsers([]);

    const cursorLocation = searchDropdown.current.selectionStart;
    const commentSegment = value.substr(0, cursorLocation);
    let lastIndex = commentSegment.lastIndexOf(' ');
    if (lastIndex === -1) {
      lastIndex = 0;
    } else {
      lastIndex += 1;
    }
    const theWord = commentSegment.substring(lastIndex, commentSegment.length);

    if (theWord.includes('@')) {
      queryUsers(theWord.substr(1));
    }

    handleChange(event.target.value);
  };

  return (
    <>
      <textarea
        className={customClass ? customClass : ''}
        placeholder={placeholder}
        name="search"
        ref={searchDropdown}
        value={comment}
        onChange={handleInputChange}
        onKeyDown={selectFirstUser}
      />
      {users.length > 0 && (
        <DropdownWrapper>
          {users.map((user) => {
            return (
              <div
                className="input-dropdown-option"
                onClick={() => selectUser(user.username)}
                key={user.username}
              >
                <UserImgDefault user={user} size="tn" />
                {user.username}
              </div>
            );
          })}
        </DropdownWrapper>
      )}
    </>
  );
};

export default SearchDropdown;
