import { createGlobalStyle } from 'styled-components';
import Hanesy from '../media/HanesyNL-Regular.otf';
import Montserrat from '../media/Montserrat.ttf';
import Pentacle from '../media/Pentacle-Gothic.otf';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Hanesy";
    src: url(${Hanesy}) format('opentype');
  }

  @font-face {
    font-family: "Pentacle";
    src: url(${Pentacle}) format('opentype');
  }

  @font-face {
    font-family: "Montserrat";
    src: url(${Montserrat}) format('truetype'); 
  }

  :root {
    color-scheme: ${({ theme }) =>
      ['Gold', 'Night'].includes(theme.name) ? 'dark' : 'light'};
  }

  body {
    background: ${({ theme }) => theme.colors.body} !important;
    color: ${({ theme }) => theme.colors.bodyText} !important;
    font-family: ${({ theme }) => theme.font};
    overflow-y: scroll;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    font-size: 17px;
  }

  div h1, div h2, div h3, div h4, div h5 {
    margin: 0px;
  }

  a:focus,
  button:focus,
  input:focus,
  textarea:focus,
  div:focus {
    outline: none;
  }

  img {
    pointer-events: none;
    font-size: 0px;
    line-height: 0px;
    display: block;
  }
  svg {
    color: ${({ theme }) => theme.colors.subtext};
    font-size: 0px;
    line-height: 0px;
    display: block;
  }

  input,
  textarea,
  .ui.form textarea,
  .ui.form input,
  .ui.form input:not([type]) {
    background-color: ${({ theme }) => theme.colors.input.background};
    border: ${({ theme }) => `1px solid ${theme.colors.input.border}`};

    &:active {
      border: ${({ theme }) => `1px solid ${theme.colors.input.border}`};
      border-color: ${({ theme }) => theme.colors.input.border};
    }
  }

  div .ui.form > p {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.bodyText};
    margin: 0;
    padding: 0;
  }

  .ui.checkbox {
    transform: scale(1.3);
    width: 26px;
    margin-left: 4px;
  }

  .ui.form .field > label,
  .ui.checkbox label {
    color: ${({ theme }) => theme.colors.bodyText} !important;
  }

  label {
    font-weight: 600;
    margin-right: 8px;
  }

  .ui.dropdown .menu {
    left: auto;
    right: 0;
  }

  .ui.dropdown .menu.transition {
    background: ${({ theme }) => theme.colors.card.background}
    color: ${({ theme }) => theme.colors.bodyText};

    .active.item span {
      font-weight: 600;
    }
  }

  div input,
  div textarea,
  div .ui.input > input,
  input,
  .ui.form input[type="text"],
  .ui.form .field textarea,
  .ui.form input:not([type]),
  textarea {
    font-size: 17px;
    border: ${({ theme }) => `1px solid ${theme.colors.input.border}`};
    background-color: ${({ theme }) => theme.colors.input.background};
    color: ${({ theme }) => theme.colors.input.text};
    border-radius: .28571429rem;
    font-family: 'Montserrat';

    &:focus {
      border-color: ${({ theme }) => theme.colors.highlight};
      background-color: ${({ theme }) => theme.colors.input.background};
      color: ${({ theme }) => theme.colors.input.text};
    }
  }

  div,
  .ui.form,
  .ui.input {
    input::placeholder,
    textarea::placeholder {
      color: ${({ theme }) => theme.colors.input.placeholder};
      font-size: 17px;
      font-family: 'Montserrat';
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.input.placeholder};
      font-size: 17px;
      font-family: 'Montserrat';
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: ${({ theme }) => theme.colors.input.placeholder};
      font-size: 17px;
      font-family: 'Montserrat';
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.input.placeholder};
      font-size: 17px;
      font-family: 'Montserrat';
    }
  }

  p {
    color: ${({ theme }) => theme.colors.bodyText};
  }

  p a {
    color: ${({ theme }) => theme.colors.highlight};
  }
  a {
    color: ${({ theme }) => theme.colors.highlight};
    position: relative;
    text-decoration: none;

    div, h1, h2, h3, h4, h5 {
      color: ${({ theme }) => theme.colors.bodyText};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.highlight};
    }
  }
  a svg {
    color: ${({ theme }) => theme.colors.subText};
  }

  button {
    font-family: 'Montserrat';
    padding: 0;
    border: none;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
  }
  
  .ui.form textarea:not([rows]),
  textarea {
    max-width: 100%;
    height: 90px;
    min-height: 90px;
  }

  h1,
  h2 {
    font-family: 'Hanesy';
  }

  p,
  h3,
  h4,
  h5,
  input,
  textarea,
  span,
  div {
    font-size: 17px;
    font-family: 'Montserrat';
    font-weight: 400;
    margin-bottom: 0px;
  }
  * {
    font-family: 'Montserrat';
  }

  .quill {
    border-radius: 6px;
    > div {
      border: none;
    }

    .ql-toolbar .ql-formats button.ql-active {
      color: ${({ theme }) => theme.colors.highlight};
    }
  }
  .ql-indent-1 {
    margin-left: 15px;
  }
  .ql-indent-2 {
    margin-left: 30px;
  }
  .ql-indent-3 {
    margin-left: 45px;
  }
  .ql-indent-4 {
    margin-left: 60px;
  }
  .ql-indent-5 {
    margin-left: 75px;
  }

  .react-datepicker-popper  {
    width: 100%;
    max-width: 340px;
  }

  .custom-picker {
    width: 100%;
    .react-datepicker__header,
    .react-datepicker__month-container {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.input.background};
    }
    .react-datepicker__month-wrapper {
      display: flex;
      justify-content: space-evenly;
      gap: 3px;
    }
    .react-datepicker__month-text {
      font-size: 14px;
      flex: 1;
    }
    .react-datepicker__month-text.react-datepicker__month--selected,
    .react-datepicker__month-text.react-datepicker__month--selected:hover {
      background-color: ${({ theme }) => theme.colors.card.background};
      color: ${({ theme }) => theme.colors.bodyText};
    }
    .react-datepicker__month-text--keyboard-selected {
      background-color: ${({ theme }) => theme.colors.card.background};
      color: ${({ theme }) => theme.colors.bodyText};
    }
  }

  .more-options-button {
    outline: none;
    &:hover {
      cursor: pointer;
    }
  }

  .more-options-item {
    text-align: center;
    padding: 4px 8px;
    &:hover {
      cursor: pointer;
    }
  }

  #react-modal-image-img{
    object-fit: contain;
  }

  .swap-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 24px 12px 24px 12px;
  }

  .ui.form .ql-toolbar.ql-snow {
    border-radius: 0.28571429rem 0.28571429rem 0 0;
    border: none;
    background-color: ${({ theme }) => theme.colors.input.background};
    border-color: ${({ theme }) => theme.colors.input.border};
    border-width: 1px 1px 0 1px;
    border-style: solid;
  }
  .ui.form .ql-container.ql-snow {
    border-width: 0;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
  }
  .ui.form div.ql-editor {
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    font-size: 17px;
    min-height: 100px;
    background-color: ${({ theme }) => theme.colors.input.background};
    border-color: ${({ theme }) => theme.colors.input.border};
    border-width: 1px;
    border-style: solid;

    p {
      color: ${({ theme }) => theme.colors.bodyText};
    }

    &::focus {
      border-color: ${({ theme }) => theme.colors.highlight};
    }

    &.ql-blank::before {
      font-style: normal;
      color: ${({ theme }) => theme.colors.input.placeholder};
    }
  }

  .drop-field {
    min-height: 80px;
    height: auto;
    padding: 0.7em;
    border: ${({ theme }) => `1px solid ${theme.colors.input.border}`};
    background-color: ${({ theme }) => theme.colors.card.background};
    justify-content: center;
    border-radius: .28571429rem;
    color: ${({ theme }) => theme.colors.bodyText};
    display: flex;
    align-items: center;
    &--small {
      min-height: auto;
    }
    input {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
    &.drop-field-icon {
      display: contents;
      svg {
        height: 24px;
        width: 24px;
      }
    }
    p {
      font-size: 17px;
      color: ${({ theme }) => theme.colors.bodyText};
    }
  }

  .drop-field-button p {
    color: ${({ theme }) => theme.colors.invertText};
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
`;
