import PrimaryButton from '@/components/library/buttons/primary-button';
import { SectionHeader } from '@/components/library/typography/header';
import { SubText } from '@/components/library/typography/text';
import themesData from '@/themes/schema.js';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: stretch;
  gap: 12px;
  flex-wrap: wrap;
`;

const Theme = styled.div`
  background: ${({ theme }) => theme.colors.card.background};
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  border-radius: 4px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const ThemeSelector = ({ patchUser }) => {
  const currentUser = useSelector((state) => state.currentUser);

  const themes = [
    {
      key: 'brand',
      name: 'Tavern',
      description: 'Full of warm and cozy colors, like a tavern',
    },
    {
      key: 'light',
      name: 'Light',
      description: "It's light mode, plain and simple",
    },
    {
      key: 'dark',
      name: 'Night',
      description: 'Turn the lights off and protect your eyes',
    },
    {
      key: 'gold',
      name: 'Gold',
      description: "It's the night theme but with gold!",
    },
  ];

  const selectedTheme = currentUser.theme;
  return (
    <Wrapper>
      {themes.map((theme) => (
        <Theme theme={themesData[theme.key]} key={theme.key}>
          <div style={{ paddingBottom: '24px' }}>
            <SectionHeader theme={themesData[theme.key]}>
              {theme.name}
            </SectionHeader>
            <SubText theme={themesData[theme.key]}>{theme.description}</SubText>
          </div>
          {selectedTheme === theme.key ? (
            <PrimaryButton className="btn" theme={themesData[theme.key]}>
              Selected
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className="btn"
              onClick={() => patchUser({ theme: theme.key })}
              theme={themesData[theme.key]}
            >
              Select
            </PrimaryButton>
          )}
        </Theme>
      ))}
    </Wrapper>
  );
};

export default ThemeSelector;
