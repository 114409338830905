import AddAudio from '@/components/add-audio';
import AddPieceSlot from '@/containers/collaboration/add-piece-slot';
import Audio from '@/containers/collaboration/audio';
import { Headphones } from 'react-feather';

const AudioBlock = ({ collab, getCollabData, editing, showEdit }) => {
  const { audio, id: collabId } = collab;

  const canAddAudio = !audio;
  return (
    <>
      {audio && (
        <Audio audio={audio} refetchData={getCollabData} collabId={collabId} />
      )}
      {canAddAudio && (
        <>
          {editing ? (
            <AddAudio
              getCollabData={getCollabData}
              collab={collab}
              resetPage={() => showEdit(null)}
            />
          ) : (
            <AddPieceSlot
              showEdit={() => showEdit(1)}
              text="Add a Piece of Music or Voice Line"
              icon={<Headphones />}
            />
          )}
        </>
      )}
    </>
  );
};

export default AudioBlock;
