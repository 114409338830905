import styled from 'styled-components';

const CommentsWrapper = styled.div`
  .comment {
    position: relative;
    margin: 28px 0 0 0 !important;
    .comment-header {
      padding-bottom: 8px;
      display: flex;
      gap: 8px;
    }
    .child-comment:last-of-type {
      .comment {
        margin: 20px 0 0 0;
      }
    }
    .comment-deleted {
      background-color: ${({ theme }) => theme.colors.card.background};
      color: ${({ theme }) => theme.colors.subtext};
      padding: 40px 20px;
      text-align: center;
      border: ${({ theme }) => `1px solid ${theme.colors.subtext}`};
      border-radius: 4px;
    }
  }

  .comment-comment {
    word-break: break-word;
  }

  .comment-footer {
    color: ${({ theme }) => theme.colors.subtext};
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    & svg {
      width: 16px;
      height: 16px;
    }

    &.reply-open {
      .comment-reply-icon,
      button {
        color: ${({ theme }) => theme.colors.highlight};
      }
    }
  }

  .reply-dialog {
    margin: 28px 0 0 0 !important;
    &.reply-nested {
      margin: 28px 0 0 36px !important;
    }
  }

  .child-comment {
    border-left: ${({ theme }) => `1px solid ${theme.colors.border}`};
    padding-left: 20px;
    margin-left: 36px;
  }
`;

export default CommentsWrapper;
