import BarLoader from 'react-spinners/BarLoader';

import bardAPI from '@/bard-api';
import ImageField from '@/components/image-field';
import {
  FlexColumn,
  FlexRowSpace,
} from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import TextButton from '@/components/library/buttons/text-button';
import { FormHeader } from '@/components/library/typography/header';
import { Text } from '@/components/library/typography/text';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  img {
    flex: 0 0 140px;
    height: 140px;
    width: 140px;
    border-radius: 140px;
    object-fit: cover;
  }

  .ui.form {
    flex: 1 0 0;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  .ui.form textarea:not([rows]) {
    height: 200px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const AboutForm = ({ nextStep, updateUser }) => {
  const [about, setAbout] = useState('');
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState(
    'Try dropping some files here, or click to select files to upload.',
  );
  const [fileUploading, setFileUploading] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const theme = useTheme();

  useEffect(() => {
    setAbout(currentUser.bio);
    setFilePath(currentUser.profile_img);
  }, [currentUser]);

  const onDrop = async (acceptedFiles) => {
    const profileImg = acceptedFiles[0];
    setFileName(profileImg.name);
    setFileUploading(true);

    const formData = new FormData();
    formData.append('profile', profileImg);

    try {
      const response = await bardAPI.post(`users/upload/`, formData);
      setFilePath(response.data);
      setFileUploading(false);
    } catch (error) {
      setFileUploading(false);
      uRequestHandler(error);
    }
  };

  const submitDetails = async () => {
    await updateUser({
      bio: about,
      profile_img: filePath,
    });
    nextStep();
  };

  return (
    <Wrapper>
      <FormHeader $margin="0 0 18px 0">
        Share a little bit about yourself
      </FormHeader>
      <Text $margin="0 0 18px 0">Who are you and what do you do?</Text>

      <FormWrapper>
        <FlexColumn $gap="1rem" $align="center">
          {filePath ? (
            <img
              src={`https://${import.meta.env.VITE_APP_S3}/${filePath}`}
              className="profile-image"
              alt="Unsuccessfully loaded."
            />
          ) : (
            <img
              src={`https://${import.meta.env.VITE_APP_S3}/profile/avatar.png`}
              className="profile-image"
              alt="Unsuccessfully loadded."
            />
          )}
          {fileUploading ? (
            <BarLoader color={theme.colors.highlight} />
          ) : (
            <ImageField
              small={true}
              onDrop={onDrop}
              label="Profile Picture"
              fileName={fileName}
            />
          )}
        </FlexColumn>
        <Form>
          <Form.Field>
            <label>About</label>
            <textarea
              placeholder="About yourself"
              name="about"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            />
          </Form.Field>
        </Form>
      </FormWrapper>

      <FlexRowSpace $margin="24px 0 0 0">
        <TextButton onClick={() => nextStep()}>Skip</TextButton>
        <PrimaryButton
          className="btn"
          onClick={submitDetails}
          disabled={fileUploading}
        >
          Continue
        </PrimaryButton>
      </FlexRowSpace>
    </Wrapper>
  );
};

export default AboutForm;
