import bardAPI from '@/bard-api';
import UserSetup from '@/containers/account/user-setup/index';
import { resetLikesAndSaves } from '@/store/landing';
import { getCurrentUser } from '@/store/user';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Gradient = styled.div`
  height: ${({ $loading }) => ($loading ? '100vh' : 'auto')};
`;

const UserProvider = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [returningToOnboarding, setReturningToOnboarding] = useState(false);
  const [overridePre, setoverridePre] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  const isCurrentUserStored = currentUser.id;
  const userNeedsToBeFetched = isAuthenticated && !isCurrentUserStored;
  const showOnboarding =
    (currentUser.onboarding_required && isCurrentUserStored) ||
    returningToOnboarding;

  useEffect(() => {
    const { search } = location;
    window.scrollTo(0, 0);

    dispatch(resetLikesAndSaves());
    if (search.includes('onboarding=true')) {
      setReturningToOnboarding(true);
    } else {
      setReturningToOnboarding(false);
    }
    if (search.includes('imjoe=true')) {
      setoverridePre(true);
    } else {
      setoverridePre(false);
    }
  }, [location]);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: import.meta.env.VITE_APP_AUTH_AUD,
            scope: 'read:current_user read:email email offline_access',
          },
        });

        bardAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        dispatch(getCurrentUser());
      } catch (e) {
        console.log(e.message);
      }
    };

    if (userNeedsToBeFetched) {
      getUserMetadata();
    }
  }, [isAuthenticated, userNeedsToBeFetched]);

  if (userNeedsToBeFetched || isLoading || currentUser.isLoading) {
    return <Gradient $loading="true"></Gradient>;
  }
  return <Gradient>{showOnboarding ? <UserSetup /> : children}</Gradient>;
};

export default UserProvider;
