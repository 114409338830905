import bardAPI from '@/bard-api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CollabsList from '@/components/collabs-list';
import { FlexRowSpace } from '@/components/library/basic-styled-components';
import {
  ContentContainer,
  PageContainer,
} from '@/components/library/containers';
import { PageHeader } from '@/components/library/typography/header';
import { Text } from '@/components/library/typography/text';
import Navbar from '@/components/navbar/index';
import CollabFilterBar from '@/containers/collection/filter-bar';
import { CONTENT_ALL, MAKER_EXPLORE } from '@/store/landing';
import { uRequestHandler } from '@/utils';

const Collection = () => {
  const [collection, setCollection] = useState(null);
  const [collabs, setCollabs] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [maker, setMaker] = useState(MAKER_EXPLORE);
  const [contentType, setContentType] = useState(CONTENT_ALL);
  const [tags, setTags] = useState([]);
  const { id: collectionId } = useParams();

  useEffect(() => {
    const getCollection = async () => {
      try {
        setLoading(true);
        const response = await bardAPI.get(`collections/${collectionId}/`);

        setCollection(response.data);
        setLoading(false);
      } catch (error) {
        uRequestHandler(error);
      }
    };

    if (collectionId) {
      getCollection();
    }
  }, [collectionId]);

  useEffect(() => {
    let isMounted = true;

    const searchCollabs = async () => {
      try {
        setLoading(true);

        let newFilter = '';
        for (let i = 0; i < tags.length; i += 1) {
          newFilter += `tag=${tags[i]}&`;
        }

        newFilter = newFilter + maker + contentType;

        const response = await bardAPI.get(
          `/collabs/?collection=${collectionId}&${newFilter}&limit=12&offset=${offset * 12}`,
        );

        if (isMounted) {
          setCollabs(response.data.results);
          setLoading(false);
        }
      } catch (error) {
        uRequestHandler(error);
      }
    };

    searchCollabs();

    return () => {
      isMounted = false;
    };
  }, [maker, contentType, tags]);

  return (
    <PageContainer>
      <Navbar />
      {!loading && collection && (
        <ContentContainer>
          <FlexRowSpace>
            <PageHeader>{collection.name}</PageHeader>
          </FlexRowSpace>

          <Text>{collection.about}</Text>

          {collection.preExisting && (
            <Text>This collection is based off a pre-existing world.</Text>
          )}

          <CollabFilterBar
            setTags={setTags}
            setContentType={setContentType}
            setMaker={setMaker}
            tags={tags}
            contentType={contentType}
            maker={maker}
            collectionId={collectionId}
          />

          <CollabsList collabs={collabs} loading={loading} />
        </ContentContainer>
      )}
    </PageContainer>
  );
};

export default Collection;
