import { ItemHeader } from '@/components/library/typography/header';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 12px 18px;
  background-color: ${({ theme }) => theme.colors.card.background};
  border: ${({ theme }) => `1px solid ${theme.colors.card.border}`};
  border-radius: 8px;
  margin-bottom: 1.5rem;
`;

const ActiveCollection = ({ collection, existingPost }) => {
  let prefix = 'You are adding this post to the collection';
  if (existingPost) {
    prefix = 'This post exists on the collection';
  }
  return (
    <Wrapper>
      <ItemHeader $margin="0 0 0.25rem">
        {prefix}{' '}
        <Link to={`/collection/${collection.id}`}>
          <strong>{collection.name}</strong>
        </Link>
      </ItemHeader>
    </Wrapper>
  );
};

export default ActiveCollection;
