import AudioPlayer from '@/components/audio-player/index';
import UserHeader from '@/components/user-header';
import { AUDIO_REPORT } from '@/utils';
import styled from 'styled-components';
import { AboutText, CollabBlockHeader, PostedDate } from './components';

const ContentCard = styled.div`
  background-color: ${({ theme }) => theme.colors.card.background};
  padding: 20px;
  border-radius: 6px;
`;

const Audio = ({ audio, refetchData, collabId }) => {
  const { name, user, about } = audio;

  return (
    <div>
      {name && <CollabBlockHeader>{name}</CollabBlockHeader>}
      <UserHeader
        user={user}
        reportObject={audio}
        reportCategory={AUDIO_REPORT}
        removeData={() => refetchData(collabId)}
      />
      {about && <AboutText>{about}</AboutText>}
      <ContentCard>
        <AudioPlayer audio={audio} />
      </ContentCard>
      <PostedDate>
        {new Date(audio.created_date).toLocaleDateString()}
      </PostedDate>
    </div>
  );
};

export default Audio;
