import PrimaryButton from '@/components/library/buttons/primary-button';
import Modal from '@/components/library/modal-override';
import { X } from 'react-feather';
import { Link } from 'react-router-dom';

const NotSignedIn = ({ showModal, setModalVisibility }) => {
  return (
    <Modal size="tiny" open={showModal}>
      <Modal.Header>
        Account Required
        <X onClick={() => setModalVisibility(false)} className="modal-close" />
      </Modal.Header>
      <Modal.Content>
        <p className="basic-text">
          You have to be logged into your account to access this feature. Go to
          the&nbsp;
          <Link to="/login">login screen</Link>
          &nbsp;to login or create an account.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <PrimaryButton
          className="btn"
          onClick={() => setModalVisibility(false)}
        >
          Close
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  );
};

export default NotSignedIn;
