import bardAPI from '@/bard-api';
import { FlexRowSpace } from '@/components/library/basic-styled-components';
import PrimaryButton from '@/components/library/buttons/primary-button';
import { FormHeader } from '@/components/library/typography/header';
import { Text } from '@/components/library/typography/text';
import ThemeSelector from '@/containers/account/components/theme-selector';
import { fetchUserAfterUpdate } from '@/store/user';
import { uRequestHandler } from '@/utils';
import { useDispatch, useSelector } from 'react-redux';

const ThemeForm = ({ nextStep }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);

  const patchUser = async (obj) => {
    try {
      await bardAPI.put(`users/${currentUser.id}/`, obj);
      dispatch(fetchUserAfterUpdate());
    } catch (error) {
      uRequestHandler(error);
    }
  };

  return (
    <>
      <FormHeader $margin="0 0 18px 0">Choose Your Environment!</FormHeader>
      <Text $margin="0 0 18px 0">
        Pick a theme that resonates with you to make this place feel like home.
      </Text>
      <ThemeSelector patchUser={patchUser} />
      <FlexRowSpace $margin="24px 0 0 0">
        <div />
        <PrimaryButton className="btn" onClick={() => nextStep()}>
          Continue
        </PrimaryButton>
      </FlexRowSpace>
    </>
  );
};

export default ThemeForm;
