import LandingReducer from '@/store/landing';
import UserReducer from '@/store/user';
import { configureStore } from '@reduxjs/toolkit';

export default configureStore({
  reducer: {
    currentUser: UserReducer,
    landingPage: LandingReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
