import bardAPI from '@/bard-api';
import {
  PageContainer,
  PageFormWrapper,
} from '@/components/library/containers';
import ProgressBar from '@/components/library/progress-bar';
import { uRequestHandler } from '@/utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import AboutForm from './about';
import ContactInfo from './contact';
import PlanSelector from './plan';
import ThemeSelectorStep from './theme';
import UsernameAndTerms from './username-and-terms';

const STEP_USERNAME = 1;
const STEP_THEME = 2;
const STEP_ABOUT = 3;
const STEP_PRO = 4;
const STEP_CONTACT = 5;

const PLAN_PRO = 'pro';
const THEME_TO_IMAGE = {
  light: 'day',
  dark: 'night',
  brand: 'sunset',
  gold: 'sunset',
};
const STEP_IMAGE = {
  [STEP_USERNAME]: 'Forest',
  [STEP_THEME]: 'Valley',
  [STEP_ABOUT]: 'Lake',
  [STEP_PRO]: 'Tropical',
  [STEP_CONTACT]: 'Desert',
};

const OnboardingContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 820px;
  padding: 0px 60px 120px 60px;

  @media (max-width: 450px) {
    min-height: 100vh;
    background-color: ${({ theme }) => theme.colors.card.background};
    margin-top: 0px;
    padding: 0px 0px 0px 0px;
  }
`;

const UserSetup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(STEP_USERNAME);
  const [background, setBackground] = useState(null);
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    const { search } = location;

    if (search.includes('onboarding=true')) {
      setStep(STEP_CONTACT);
    }
  }, [location]);

  const updateUser = async (data) => {
    try {
      await bardAPI.put(`users/${currentUser.id}/`, data);
    } catch (error) {
      uRequestHandler(error);
    }
  };

  const finishAbout = () => {
    // if (currentUser.selected_plan === PLAN_PRO) {
    setStep(STEP_CONTACT);
    // } else {
    //   setStep(STEP_PRO);
    // }
  };

  const finishTheme = () => {
    updateUser({ onboarding_required: false });
    setStep(STEP_ABOUT);
  };

  useEffect(() => {
    const loadImage = () => {
      import(
        `../../../media/scenes/${STEP_IMAGE[step]}-${
          THEME_TO_IMAGE[currentUser.theme]
        }.gif`
      ).then((image) => {
        setBackground(image.default);
      });
    };

    loadImage();
  }, [step, currentUser]);

  return (
    <PageContainer>
      <OnboardingContainer>
        {background && (
          <img
            src={background}
            alt="background"
            style={{
              width: '100%',
              height: 300,
              objectFit: 'cover',
              objectPosition: 'top',
              borderRadius: '6px 6px 0 0',
              display: 'block',
              verticalAlign: 'top',
              fontSize: '0px',
              lineHeight: '0px',
            }}
          />
        )}
        <ProgressBar progress={step} total={5 - step} />
        <PageFormWrapper radius={'0 0 6px 6px'}>
          {step === STEP_USERNAME && (
            <UsernameAndTerms
              nextStep={() => setStep(STEP_THEME)}
              updateUser={updateUser}
            />
          )}
          {step === STEP_THEME && <ThemeSelectorStep nextStep={finishTheme} />}
          {step === STEP_ABOUT && (
            <AboutForm nextStep={finishAbout} updateUser={updateUser} />
          )}
          {step === STEP_PRO && (
            <PlanSelector nextStep={() => setStep(STEP_CONTACT)} />
          )}
          {step === STEP_CONTACT && (
            <ContactInfo
              nextStep={() => {
                navigate('/');
                window.location.reload();
              }}
              updateUser={updateUser}
            />
          )}
        </PageFormWrapper>
      </OnboardingContainer>
    </PageContainer>
  );
};

export default UserSetup;
