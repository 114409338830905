import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const ModalOverride = styled(Modal)`
  &.ui.modal {
    .header,
    .content,
    .actions {
      color: ${({ theme }) => theme.colors.bodyText};
      background: ${({ theme }) => theme.colors.card.background};
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      padding: 1rem !important;
    }
  }
`;

export default ModalOverride;
