import S3Visual from '@/components/library/s3-visual';
import UserHeader from '@/components/user-header';
import { VISUAL_REPORT } from '@/utils';
import { AboutText, CollabBlockHeader, PostedDate } from './components';

const Visual = ({ visual, refetchData, collabId }) => {
  const { name, user, file_path, about } = visual;

  return (
    <div>
      {name && <CollabBlockHeader>{name}</CollabBlockHeader>}
      <UserHeader
        user={user}
        reportObject={visual}
        reportCategory={VISUAL_REPORT}
        removeData={() => refetchData(collabId)}
      />
      {about && <AboutText>{about}</AboutText>}
      <S3Visual path={file_path} enableWide={true} enableEnlarge={true} />
      <PostedDate>
        {new Date(visual.created_date).toLocaleDateString()}
      </PostedDate>
    </div>
  );
};

export default Visual;
